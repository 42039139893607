import React, { Component } from 'react';
import BackButtonHeader from '../../Elements/BackButtonHeader';
import Loader from '../../Delivery/Loader';
import DatePicker from "react-datepicker";
import Axios from "axios";
import { connect } from "react-redux";
import Moment from "react-moment";
import { updateDeliveryUserInfo } from "../../../services/Delivery/user/actions";
import { TRANSACTION_HISTORY_VIEW_URL } from '../../../configs';
import "react-datepicker/dist/react-datepicker.css";
class EarningHistory extends Component {
    state = {
        transactions: [],
        page: 0,
        last_page: 0,
        from: new Date(),
        to: new Date(),
    }


    componentDidMount() {
        this.loadMore();
    }
    loadMore = (page_count) => {
        let count;
        if(page_count == 0){
            count = page_count; 
        }else{
            count = this.state.page;
        }
        this.setState({ loading: true });
        Axios.post(TRANSACTION_HISTORY_VIEW_URL, {
            token: this.props.delivery_user.data.auth_token,
            page: count + 1,
            date_filter: this.state.date_filter,
            from: this.state.from,
            to: this.state.to
        })
            .then(response => {
                console.log(response);

                const data = response.data.data;
                const otherDetails = response.data;

                if (data) {
                    this.setState({
                        transactions: [...this.state.transactions, ...data.data],
                        page: data.current_page,
                        last_page: data.last_page,
                        loading: false,
                        balance: otherDetails.balance,
                        total_earnings: otherDetails.total_earnings,

                    });
                }
            });
    }

    handleStartDate = (event) => {
        this.setState({
            from: new Date(event),
        });
    };

    handleEndDate = (event) => {
        this.setState({
            to: new Date(event),
        });
    };


    render() {
        return (
            <React.Fragment>
                <BackButtonHeader title={localStorage.getItem('transaction_details')} />
                {this.state.loading && <Loader />}
                <div className="container">

                    <div className="d-flex justify-content-between">
                        <div className="text-center " style={{ borderRadius: '10px', padding: '10px', color: 'white', width: '49%', background: 'linear-gradient(276deg, rgb(79, 200, 255) 0%, rgb(69, 118, 255) 50.52%, rgb(0, 42, 127) 100%)' }}>
                            <div className="font-weight-bold" style={localStorage.getItem('lang') && localStorage.getItem('lang') == "en" ? { fontSize: '21px',direction:'ltr' }:{fontSize: '21px',direction:'rtl'}}>{localStorage.getItem('currency')}{parseFloat(this.state.balance).toFixed(2)}</div>
                            <div>{localStorage.getItem('current_balance')}</div>

                        </div>
                        <div className="text-center " style={{ borderRadius: '10px', padding: '10px', color: 'white', width: '49%', background: 'linear-gradient(87deg, rgb(79, 200, 255) 0%, rgb(69, 118, 255) 50.52%, rgb(0, 42, 127) 100%)' }}>
                            <div className="font-weight-bold" style={localStorage.getItem('lang') && localStorage.getItem('lang') == "en" ? { fontSize: '21px',direction:'ltr' }:{fontSize: '21px',direction:'rtl'}}>{localStorage.getItem('currency')}{parseFloat(this.state.total_earnings).toFixed(2)}</div>
                            <div>{localStorage.getItem('total_earnings')}</div>
                        </div>
                    </div>

                    <div onClick={() => this.setState({ date_filter: !this.state.date_filter })} className={`btn mt-2 ${this.state.date_filter ? 'btn-primary' : 'btn-secondary'}`}>
                        {localStorage.getItem('enable_date_filter')}
                    </div>

                    {this.state.date_filter &&
                    <>
                        <div className="d-flex justify-content-between text-black mt-4">

                            <div style={{width: "49%",}}>
                                <div>{localStorage.getItem('from')}</div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: 'center',
                                        marginTop: "5px",
                                        paddingLeft: "10px",
                                        height: "50px",
                                        width: "100%",
                                        boxShadow: "0.12rem 0.12rem 0.12rem 0.12rem #F2F2F2",
                                        borderRadius: "0.5rem",
                                        border: "0.06em solid #200E32",
                                        backgroundColor: "#FFF",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <DatePicker
                                        onChange={this.handleStartDate}
                                        selected={this.state.from}
                                        className="red-border"
                                    />
                                </div>
                            </div>
                            <div style={{width: "49%",}}>
                                <div>{localStorage.getItem('to')}</div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "5px",
                                        justifyContent: 'center',

                                        paddingLeft: "10px",
                                        height: "50px",
                                        width: "100%",
                                        boxShadow: "0.12rem 0.12rem 0.12rem 0.12rem #F2F2F2",
                                        borderRadius: "0.5rem",
                                        border: "0.06em solid #200E32",
                                        backgroundColor: "#FFF",
                                        marginBottom: "10px",
                                    }}
                                >

                                    <DatePicker
                                        onChange={this.handleEndDate}
                                        selected={this.state.to}
                                        className="red-border"
                                    />
                                </div>
                            </div>
                        </div>

                        <button className="btn btn-primary" onClick={() => this.setState({ transactions: []}, this.loadMore(0))}>{localStorage.getItem('apply')}</button>
                        <hr/>
                        </>
                    }

                    {this.state.transactions && this.state.transactions.length > 0
                        ? this.state.transactions.map((transaction, index) =>


                            <div className="row mt-4 text-black">
                                <div className="col-12 ">
                                    <div class="card " style={{ borderRadius: '10px', }} >
                                        <div className="  p-20" style={{
                                            border: 'none',
                                            borderRadius: '10px',
                                            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                                            WebkitBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                                            MozBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)"
                                        }}>

                                            <div className="d-flex justify-content-between">
                                                <div>{transaction.type == 'deposit' ? <span style={{ color: '#008000', fontWeight: 'bold' }}>{localStorage.getItem('deposit')}</span> : <span style={{ color: 'red', fontWeight: 'bold' }}>{localStorage.getItem('withdraw')}</span>} | #{transaction.id}</div>
                                                <div className="font-weight-bold" style={localStorage.getItem('lang') && localStorage.getItem('lang') == "en" ? {direction:'ltr' }:{direction:'rtl'}}>{localStorage.getItem('currency')}{parseFloat((transaction.amount) / 100).toFixed(2)}</div>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                                <div className="text-muted">{transaction.meta ? transaction.meta.description : null}</div>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <Moment format="DD/MM/YYYY">{transaction.created_at}</Moment>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    {this.state.page != this.state.last_page ?
                        <div className="btn btn-secondary" style={{ "width": "100%", "fontSize": "16px", marginTop: '15px', marginBottom: '15px' }} onClick={() => this.loadMore()}>
                           {localStorage.getItem('load_more')}
                        </div>
                        :
                        <div className="btn btn-secondary" style={{ "width": "100%", "fontSize": "16px", marginTop: '15px', marginBottom: '15px' }}>
                            - {localStorage.getItem('end')} -
                        </div>
                    }

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    delivery_user: state.delivery_user.delivery_user,
});

export default connect(
    mapStateToProps,
    { updateDeliveryUserInfo }
)(EarningHistory);