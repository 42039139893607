// import * as firebase from "firebase/app";
import React, { Component } from "react";
// import { Redirect } from "react-router";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Iconly } from "react-iconly";
import Axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

class Footer extends Component {
  componentDidMount() {
    if (!localStorage.getItem("lang")) {
      Axios.post(
        `https://fleet.howincloud.com/api/v1/${localStorage.getItem(
          "tenant"
        )}/get-translations`,
        {
          lang: "en",
        }
      ).then((response) => {
        if (response.data.lang) {
          const langs = response.data.lang.data;
          localStorage.setItem("lang", "en");
          langs.forEach((item) => {
            localStorage.setItem(item.key, item.value);
          });
          const settings = response.data.settings;
          settings.forEach((item) => {
            localStorage.setItem(item.key, item.value);
          });
        }

        window.location.reload();
      });
    }
  }

  render() {
    const { delivery_user } = this.props;

    return (
      <React.Fragment>

<Toaster 
  position="bottom-center"
  toastOptions={{
    success: {
      duration: 3000,
      style: {
        background: 'green',
        color: 'white'
      },
    },
    error: {
      duration: 3000,
      style: {
        background: 'red',
        color: 'white'
        
      },
    },
  }}
 />

        <div className='footer'>
          <Link
            to={"/orders/new_orders"}
            className={`footer-tem  ${
              this.props.current_page === "new_orders"
                ? "active d-flex"
                : "text-black"
            }`}>
            {this.props.current_page === "new_orders" ? (
              <>
                <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.684 2.603C16.205 1.944 15.714 0.999998 14.848 0.999998H8.13199C7.95014 0.99867 7.77101 1.04415 7.61181 1.13207C7.45262 1.21998 7.31872 1.34738 7.22299 1.502L2.14099 9.958C1.73999 10.624 2.24399 11.455 3.04899 11.455H6.47799L3.24799 19.52C2.78099 20.54 4.04299 21.473 4.89099 20.735L18 8.331H11.151L15.684 2.603V2.603Z" stroke="#1167A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div className='ml-2'>{localStorage.getItem("latest")}</div>
              </>
            ) : (
              <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.684 2.603C16.205 1.944 15.714 0.999998 14.848 0.999998H8.13199C7.95014 0.99867 7.77101 1.04415 7.61181 1.13207C7.45262 1.21998 7.31872 1.34738 7.22299 1.502L2.14099 9.958C1.73999 10.624 2.24399 11.455 3.04899 11.455H6.47799L3.24799 19.52C2.78099 20.54 4.04299 21.473 4.89099 20.735L18 8.331H11.151L15.684 2.603V2.603Z" stroke="#D2D2D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            )}
          </Link>
          <Link
            to={"/orders/accepted_orders"}
            className={`footer-tem ${
              this.props.current_page === "accepted_orders"
                ? "active  d-flex"
                : "text-black"
            }`}>
            {this.props.current_page === "accepted_orders" ? (
              <>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.75 22.16L14 19.16L15.16 18L16.75 19.59L20.34 16L21.5 17.41L16.75 22.16ZM18 2C19.1 2 20 2.9 20 4V13.34C19.37 13.12 18.7 13 18 13V4H13V12L10.5 9.75L8 12V4H6V20H12.08C12.2 20.72 12.45 21.39 12.8 22H6C4.9 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2H18Z" fill="#1167A3"/>
              </svg>
              <div className='ml-2'>{localStorage.getItem("accepted")}</div>

              </>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.75 22.16L14 19.16L15.16 18L16.75 19.59L20.34 16L21.5 17.41L16.75 22.16ZM18 2C19.1 2 20 2.9 20 4V13.34C19.37 13.12 18.7 13 18 13V4H13V12L10.5 9.75L8 12V4H6V20H12.08C12.2 20.72 12.45 21.39 12.8 22H6C4.9 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2H18Z" fill="#D2D2D2"/>
              </svg>
              
            )}
          </Link>
          <Link
            to={"/orders/picked_up_orders"}
            className={`footer-tem   ${
              this.props.current_page === "picked_up_orders"
                ? "active  d-flex"
                : "text-black"
            }`}>
            {this.props.current_page === "picked_up_orders" ? (
              <>
             
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.58335 15.8125L12.4584 16.7708C12.4584 16.7708 19.6459 15.3333 20.6042 15.3333C21.5625 15.3333 21.5625 16.2917 20.6042 17.25C19.6459 18.2083 16.2917 21.0833 13.4167 21.0833C10.5417 21.0833 8.62502 19.6458 6.70835 19.6458H1.91669" stroke="#1167A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.91669 13.8958C2.87502 12.9375 4.79169 11.5 6.70835 11.5C8.62502 11.5 13.1771 13.4167 13.8959 14.375C14.6146 15.3333 12.4584 16.7708 12.4584 16.7708M7.66669 8.62501V4.79168C7.66669 4.53751 7.76765 4.29376 7.94738 4.11403C8.1271 3.93431 8.37085 3.83334 8.62502 3.83334H20.125C20.3792 3.83334 20.6229 3.93431 20.8027 4.11403C20.9824 4.29376 21.0834 4.53751 21.0834 4.79168V12.4583" stroke="#1167A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9792 3.83334H16.7709V8.14584H11.9792V3.83334Z" stroke="#1167A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div className='ml-2'>{localStorage.getItem("picked_up")}</div>
              </>
            ) : (
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.58335 15.8125L12.4584 16.7708C12.4584 16.7708 19.6459 15.3333 20.6042 15.3333C21.5625 15.3333 21.5625 16.2917 20.6042 17.25C19.6459 18.2083 16.2917 21.0833 13.4167 21.0833C10.5417 21.0833 8.62502 19.6458 6.70835 19.6458H1.91669" stroke="#D2D2D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.91669 13.8958C2.87502 12.9375 4.79169 11.5 6.70835 11.5C8.62502 11.5 13.1771 13.4167 13.8959 14.375C14.6146 15.3333 12.4584 16.7708 12.4584 16.7708M7.66669 8.62501V4.79168C7.66669 4.53751 7.76765 4.29376 7.94738 4.11403C8.1271 3.93431 8.37085 3.83334 8.62502 3.83334H20.125C20.3792 3.83334 20.6229 3.93431 20.8027 4.11403C20.9824 4.29376 21.0834 4.53751 21.0834 4.79168V12.4583" stroke="#D2D2D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9792 3.83334H16.7709V8.14584H11.9792V3.83334Z" fill="white" stroke="#D2D2D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>  
              
            )}
          </Link>
          <Link
            to={"/profile"}
            className={`footer-tem ${
              this.props.current_page === "profile"
                ? "active d-flex"
                : "text-black"
            }`}>
            {this.props.current_page === "profile" ? (
              <>
               <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 0C8.01109 0 7.04439 0.293245 6.22215 0.842652C5.3999 1.39206 4.75904 2.17295 4.3806 3.08658C4.00216 4.00021 3.90315 5.00555 4.09607 5.97545C4.289 6.94536 4.7652 7.83627 5.46447 8.53553C6.16373 9.2348 7.05464 9.711 8.02455 9.90393C8.99445 10.0969 9.99979 9.99784 10.9134 9.6194C11.827 9.24096 12.6079 8.6001 13.1573 7.77785C13.7068 6.95561 14 5.98891 14 5C14 3.67392 13.4732 2.40215 12.5355 1.46447C11.5979 0.526784 10.3261 0 9 0ZM9 8C8.40666 8 7.82664 7.82405 7.33329 7.49441C6.83994 7.16476 6.45542 6.69623 6.22836 6.14805C6.0013 5.59987 5.94189 4.99667 6.05764 4.41473C6.1734 3.83279 6.45912 3.29824 6.87868 2.87868C7.29824 2.45912 7.83279 2.1734 8.41473 2.05764C8.99667 1.94189 9.59987 2.0013 10.1481 2.22836C10.6962 2.45542 11.1648 2.83994 11.4944 3.33329C11.8241 3.82664 12 4.40666 12 5C12 5.79565 11.6839 6.55871 11.1213 7.12132C10.5587 7.68393 9.79565 8 9 8ZM18 19V18C18 16.1435 17.2625 14.363 15.9497 13.0503C14.637 11.7375 12.8565 11 11 11H7C5.14348 11 3.36301 11.7375 2.05025 13.0503C0.737498 14.363 0 16.1435 0 18V19H2V18C2 16.6739 2.52678 15.4021 3.46447 14.4645C4.40215 13.5268 5.67392 13 7 13H11C12.3261 13 13.5979 13.5268 14.5355 14.4645C15.4732 15.4021 16 16.6739 16 18V19H18Z" fill="#1167A3"/>
              </svg>
              <div className='ml-2'>{localStorage.getItem("profile")}</div>

              </>
            ) : (
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 0C8.01109 0 7.04439 0.293245 6.22215 0.842652C5.3999 1.39206 4.75904 2.17295 4.3806 3.08658C4.00216 4.00021 3.90315 5.00555 4.09607 5.97545C4.289 6.94536 4.7652 7.83627 5.46447 8.53553C6.16373 9.2348 7.05464 9.711 8.02455 9.90393C8.99445 10.0969 9.99979 9.99784 10.9134 9.6194C11.827 9.24096 12.6079 8.6001 13.1573 7.77785C13.7068 6.95561 14 5.98891 14 5C14 3.67392 13.4732 2.40215 12.5355 1.46447C11.5979 0.526784 10.3261 0 9 0ZM9 8C8.40666 8 7.82664 7.82405 7.33329 7.49441C6.83994 7.16476 6.45542 6.69623 6.22836 6.14805C6.0013 5.59987 5.94189 4.99667 6.05764 4.41473C6.1734 3.83279 6.45912 3.29824 6.87868 2.87868C7.29824 2.45912 7.83279 2.1734 8.41473 2.05764C8.99667 1.94189 9.59987 2.0013 10.1481 2.22836C10.6962 2.45542 11.1648 2.83994 11.4944 3.33329C11.8241 3.82664 12 4.40666 12 5C12 5.79565 11.6839 6.55871 11.1213 7.12132C10.5587 7.68393 9.79565 8 9 8ZM18 19V18C18 16.1435 17.2625 14.363 15.9497 13.0503C14.637 11.7375 12.8565 11 11 11H7C5.14348 11 3.36301 11.7375 2.05025 13.0503C0.737498 14.363 0 16.1435 0 18V19H2V18C2 16.6739 2.52678 15.4021 3.46447 14.4645C4.40215 13.5268 5.67392 13 7 13H11C12.3261 13 13.5979 13.5268 14.5355 14.4645C15.4732 15.4021 16 16.6739 16 18V19H18Z" fill="#D2D2D2"/>
              </svg>
              
              
            )}
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_user: state.delivery_user.delivery_user,
});

export default connect(mapStateToProps)(Footer);
