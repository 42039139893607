import React, { Component } from "react";
import Select from "react-select";

class BankingDetails extends Component {
  state = {
    holder: "",
    account: "",
    ifsc: "",
    bank: "",
    upi: "",
    options: []
  };


  componentDidMount() {
    if(localStorage.getItem("show_whatsapp") !== 'true'){
      const options = [
        { value: "HDFC Bank", label: "HDFC Bank" },
        { value: "ESAF Bank", label: "ESAF Bank" },
        { value: "Allahabad Bank", label: "Allahabad Bank" },
        { value: "Andhra Bank", label: "Andhra Bank" },
        { value: "Axis Bank", label: "Axis Bank" },
        {
          value: "Bank of Bahrain and Kuwait",
          label: "Bank of Bahrain and Kuwait",
        },
        {
          value: "Bank of Baroda - Corporate Banking",
          label: "Bank of Baroda - Corporate Banking",
        },
        {
          value: "Bank of Baroda - Retail Bankingk",
          label: "Bank of Baroda - Retail Bankingk",
        },
        { value: "Bank of India", label: "Bank of India" },
        { value: "Bank of Maharashtra", label: "Bank of Maharashtra" },
        { value: "Canara Bank", label: "Canara Bank" },
        { value: "Central Bank of India", label: "Central Bank of India" },
        { value: "City Union Bank", label: "City Union Bank" },
        { value: "Corporation Bank", label: "Corporation Bank" },
        { value: "Deutsche Bank", label: "Deutsche Bank" },
        { value: "Development Credit Bank", label: "Development Credit Bank" },
        { value: "Dhanlaxmi Bank", label: "Dhanlaxmi Bank" },
        { value: "Federal Bank", label: "Federal Bank" },
        { value: "ICICI Bank", label: "ICICI Bank" },
        { value: "IDBI Bank", label: "IDBI Bank" },
        { value: "Indian Bank", label: "Indian Bank" },
        { value: "Indian Overseas Ban", label: "Indian Overseas Ban" },
        { value: "IndusInd Bank", label: "IndusInd Bank" },
        { value: "ING Vysya Bank", label: "ING Vysya Bank" },
        { value: "Jammu and Kashmir Bank", label: "Jammu and Kashmir Bank" },
        { value: "Karnataka Bank Ltd", label: "Karnataka Bank Ltd" },
        { value: "Karur Vysya Bank", label: "Karur Vysya Bank" },
        { value: "Kerala Gramin Bank", label: "Kerala Gramin Bank" },
        { value: "Kotak Bank", label: "Kotak Bank" },
        { value: "Laxmi Vilas Bank", label: "Laxmi Vilas Bank" },
        {
          value: "Oriental Bank of Commerce",
          label: "Oriental Bank of Commerce",
        },
        {
          value: "Punjab National Bank - Corporate Banking",
          label: "Punjab National Bank - Corporate Banking",
        },
        {
          value: "'Punjab National Bank - Retail Banking",
          label: "'Punjab National Bank - Retail Banking",
        },
        { value: "Punjab & Sind Bank", label: "Punjab & Sind Bank" },
        {
          value: "Shamrao Vitthal Co-operative Bank",
          label: "Shamrao Vitthal Co-operative Bank",
        },
        { value: "South Indian Bank", label: "South Indian Bank" },
        {
          value: "State Bank of Bikaner & Jaipur",
          label: "State Bank of Bikaner & Jaipur",
        },
        { value: "State Bank of Hyderabad", label: "State Bank of Hyderabad" },
        { value: "State Bank of India", label: "State Bank of India" },
        { value: "State Bank of Mysore", label: "State Bank of Mysore" },
        { value: "State Bank of Patiala", label: "State Bank of Patiala" },
        { value: "State Bank of Travancore", label: "State Bank of Travancore" },
        { value: "Syndicate Bank", label: "Syndicate Bank" },
        {
          value: "Tamilnad Mercantile Bank Ltd.",
          label: "Tamilnad Mercantile Bank Ltd.",
        },
        { value: "UCO Bank", label: "UCO Bank" },
        { value: "Union Bank of India", label: "Union Bank of India" },
        { value: "United Bank of India", label: "United Bank of India" },
        { value: "Vijaya Bank", label: "Vijaya Bank" },
      ];

      this.setState({options: options});

    }else{
      const options = [
        { value: "Maybank", label: "Maybank" },
        { value: "Cimb Bank", label: "Cimb Bank" },
        { value: "Affin Bank", label: "Affin Bank" },
        { value: "Agro Bank", label: "Agro Bank" },
        { value: "Ambank", label: "Ambank" },
        { value: "BSN", label: "BSN" },
        { value: "Bank Islam", label: "Bank Islam" },
        { value: "Bank Muamalat", label: "Bank Muamalat" },
        { value: "Bank Rakyat", label: "Bank Rakyat" },
        { value: "HSBC Bank", label: "HSBC Bank" },
        { value: "Hong Leong Bank", label: "Hong Leong Bank" },
        { value: "OCBC Bank", label: "OCBC Bank" },
        { value: "RHB Bank", label: "RHB Bank" },
        { value: "Public Bank", label: "Public Bank" },
        { value: "Standard Chartered", label: "Standard Chartered" },
        { value: "UOB Bank", label: "UOB Bank" }
      ];
      this.setState({options: options});

    }


  }
  handleInputAddress = (event) => {
    this.setState({ address: event.target.value });
  };

  render() {

    
    
    const { user } = this.props;
    return (
      <>
        <div className="mt-2 ml-15 mr-15">
          <div className="form-group mt-2">
            <label className="form-label" style={{ color: "#000000" }}>
            {localStorage.getItem('bank_holder_name')}:
            </label>
            <input
              className="form-control"
              type="text"
              size="large"
              defaultValue={user.holder_name}
              required
              onChange={(e) => this.setState({ holder: e.target.value })}
              name="bank_holder"
              placeholder=  {localStorage.getItem('enter_bank_holder_name')}
            />
          </div>
        </div>
        <div className="mt-2 ml-15 mr-15">
          <div className="form-group mt-2">
            <label className="form-label" style={{ color: "#000000" }}>
            {localStorage.getItem('account_number')}:
            </label>
            <input
              className="form-control"
              type="text"
              size="large"
              defaultValue={user.account_no}
              required
              onChange={(e) => this.setState({ account: e.target.value })}
              name="acc_no"
              placeholder= {localStorage.getItem('enter_account_number')}
            />
          </div>
        </div>

        <div className="mt-2 ml-15 mr-15">
          <div className="form-group mt-2">
            <label className="form-label" style={{ color: "#000000" }}>
            {localStorage.getItem('ifsc_number')}:
            </label>
            <input
              className="form-control"
              type="text"
              size="large"
              defaultValue={user.ifsc}
              required
              onChange={(e) => this.setState({ ifsc: e.target.value })}
              name="ifsc"
              placeholder= {localStorage.getItem('enter_ifsc_number')}
            />
          </div>
        </div>
        <div className="mt-2 ml-15 mr-15">
          <div className="form-group mt-2" style={{ color: "#000" }}>
            <label className="form-label" style={{ color: "#000000" }}>
            {localStorage.getItem('bank_name')}:{user.bank_name}
            </label>
            <Select
              defaultValue={this.state.options.filter(
                (option) => option.value === user.bank_name
              )}
              options={this.state.options}
              onChange={(e) => this.setState({ bank: e.value })}
            />
          </div>
        </div>
        <div className="mt-2 ml-15 mr-15">
          <div className="form-group mt-2">
            <label className="form-label" style={{ color: "#000000" }}>
            {localStorage.getItem('upi_id')}:
            </label>
            <input
              className="form-control"
              defaultValue={user.upi}
              type="text"
              size="large"
              onChange={(e) => this.setState({ upi: e.target.value })}
              name="upi"
              placeholder={localStorage.getItem('enter_upi_id')}
            />
          </div>
        </div>
        <div
          onClick={() =>
            this.props.onBanKDetails(
              this.state.holder,
              this.state.account,
              this.state.ifsc,
              this.state.bank,
              this.state.upi
            )
          }
          className="container update-button-bottom mb-5"
        >
          <div class="text-center">
            <button
              onClick={this.onUpdate}
              className="btn btn-block"
              style={{
                borderRadius: "5px",
                backgroundColor: "#003282",
                border: "1px solid #003282",
                color: "white",
                height: "40px",
              }}
            >
              {localStorage.getItem('update_bank_details')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default BankingDetails;
