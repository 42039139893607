import { ACCEPT_TO_DELIVER, PICKEDUP_ORDER, NOT_NEAR_CUSTOMER ,REACHED_RESTAURANT} from "./actionTypes";

const initialState = {
    accepted_order: [],
    pickedup_order: [],
    reached_restaurant: [],
    not_near_customer: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACCEPT_TO_DELIVER:
            return { ...state, accepted_order: action.payload };
        case PICKEDUP_ORDER:
            return { ...state, pickedup_order: action.payload };
            case REACHED_RESTAURANT:
            return { ...state, pickedup_order: action.payload };
        case NOT_NEAR_CUSTOMER:
            return { ...state, not_near_customer: action.payload };

        default:
            return state;
    }
}
