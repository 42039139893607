import React, { Component } from "react";

import { connect } from "react-redux";

import "../../assets/css/custom.css"
import Delivery from "../Delivery";
import DeliveryDesktop from "../Delivery/DeliveryDesktop";

class App extends Component {

	componentDidMount() {
		if(this.props.match.params.tenant) {
			localStorage.setItem('tenant', this.props.match.params.tenant);
			this.props.history.push('/');
		}
	}
	render() {
		return (
			<React.Fragment>
				{window.innerWidth <= 768 ? (
					<Delivery page={'new_orders'} />
				) : (
					<DeliveryDesktop languages={this.props.languages} />
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
});

export default connect(
	mapStateToProps,
)(App);
