import React, { Component } from 'react';
import BackButtonHeader from '../../Elements/BackButtonHeader';
import Loader from '../../Delivery/Loader';
// import DatePicker from "react-datepicker";
import { Input, Button, DatePicker, Divider } from 'antd';

import Axios from "axios";
import { connect } from "react-redux";
import moment from 'moment';
import { updateDeliveryUserInfo } from "../../../services/Delivery/user/actions";
import { TASK_HISTORY_VIEW_URL } from '../../../configs';
import "react-datepicker/dist/react-datepicker.css";
class TaskHistory extends Component {
    state = {
        orders: [],
    }
    onRequest = () => {

        this.setState({ loading: true });
        Axios.post(TASK_HISTORY_VIEW_URL, {
            token: this.props.delivery_user.data.auth_token,
            start_date: this.state.start_date,
            end_date: this.state.end_date
        })
            .then(response => {
                console.log(response);

                const data = response.data;
                if (data) {
                    this.setState({ orders: data, loading: false });
                }
            });
    }


    state = {
        start_date: new Date(),
        end_date: new Date(),
        loading: false,
    };

    handleStartDate = (event) => {

        this.setState({
            start_date: new Date(event),
        });
    };

    handleEndDate = (event) => {
        this.setState({
            end_date: new Date(event),
        });
    };

    render() {
        return (
            <React.Fragment>
                <BackButtonHeader title={localStorage.getItem('completed_and_cancelled_tasks')} />


                {/* {this.state.loading &&
                 <Loader />

                }  */}

                <div className="container">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                        }}
                    >
                        <div className="d-flex justify-content-around  text-black">
                            <div style={{ width: '48%' }}><label

                                style={{
                                    color: "black",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                }}
                            >
                                {localStorage.getItem('from')}
                            </label>


                                <DatePicker
                                    onChange={this.handleStartDate}
                                    size={'large'}
                                    defaultValue={moment(new Date(), 'DD-MM-YYYY')}
                                    format={'DD-MM-YYYY'}
                                />


                            </div>
                            <div style={{ width: '48%' }}><label

                                style={{
                                    color: "black",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                }}
                            >
                                {localStorage.getItem('to')}

                            </label>

                                <DatePicker
                                    onChange={this.handleEndDate}
                                    size={'large'}
                                    defaultValue={moment(new Date(), 'DD-MM-YYYY')}
                                    format={'DD-MM-YYYY'}
                                />

                            </div>


                        </div>
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <Button onClick={this.onRequest} loading={this.state.loading} type="primary" size={'large'}>{localStorage.getItem('view_tasks')}</Button>
                        </div>
                    </div>

                    <Divider orientation="left" plain>
                        <b>{localStorage.getItem('tasks')} ({!this.state.orders ? 0 : this.state.orders.length})</b>
                    </Divider>
                    {this.state.orders && this.state.orders.length > 0
                        ? this.state.orders.map((order, index) =>


                            <div className="row mt-4">
                                <div className="col-12 ">
                                    <div class="card " style={{ borderRadius: '10px', }} >
                                        <div className="  p-20" style={{
                                            border: 'none',
                                            borderRadius: '10px',
                                            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                                            WebkitBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                                            MozBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)"
                                        }}>


                                            <div className="row text-black">
                                                <div className="col-12">
                                                    <div className="">
                                                        <span className="text-dark font-weight-bold">
                                                            {/* {Moment(order.created_at).format('YYYY-MM-DD')} */}
                                                            {order.pickup_name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between  text-black">
                                                <div>
                                                    {order.order_status_id == '8' &&
                                                        <React.Fragment>
                                                            <div>{localStorage.getItem('earning')} : {order.delivery_charge}</div>
                                                            <div>{localStorage.getItem('tips_surge')} : {order.extra_bonus ? order.extra_bonus : '0.00'}</div>
                                                            <div>{localStorage.getItem('cod')}  : {order.order_cod ? order.order_cod : '0.00'}</div>

                                                        </React.Fragment>
                                                    }
                                                </div>
                                                <div>
                                                    <div className="text-right">{order.order_status_id == '8' && <span style={{ color: 'green' }}>{localStorage.getItem('completed')}</span>} {order.order_status_id == '11' && <span style={{ color: 'red' }}>{localStorage.getItem('cancelled')}</span>}</div>
                                                    <div className="text-right">#{order.client_order_identifier.slice(-8)}</div>
                                                    <div className="text-right font-weight-bold" style={{ color: '#5fac07' }}>{localStorage.getItem('total_earnings')} : {(parseFloat((order.extra_bonus ? order.extra_bonus : 0)) + parseFloat(order.delivery_charge)).toFixed(2)}</div>

                                                </div>
                                            </div>


                                            {/* <div className="row mt-2">
                                                <div className="col-12">
                                                    {order.description ? (
                                                        <span className="text-justify text-muted">
                                                            {order.description}
                                                        </span>
                                                    ) : (
                                                        <span className="text-justify text-muted">
                                                            {order.message}
                                                        </span>
                                                    )}

                                                </div>
                                            </div> */}
                                            {/* {order.orders.map((order, index) => (
                                                <div className="d-flex justify-content-between text-black">
                                                    <div>{order.client_order_identifier}</div>
                                                    <div>{order.order_cod}</div>
                                                </div>
                                            ))} */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                </div>
            </React.Fragment>
        );
    }
}
const datePickerStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    paddingLeft: "10px",
    height: "50px",
    width: "100%",
    boxShadow: "0.06em solid rgb(179 179 179)",
    borderRadius: "0.5rem",
    border: "0.06em solid #200E32",
    backgroundColor: "#FFF",
    marginBottom: "10px",
    zIndex: "10",
};
const mapStateToProps = (state) => ({
    delivery_user: state.delivery_user.delivery_user,
});

export default connect(
    mapStateToProps,
    { updateDeliveryUserInfo }
)(TaskHistory);