import { combineReducers } from "redux";



import deliveryUserReducer from "./Delivery/user/reducer";
import deliveryOrdersReducer from "./Delivery/orders/reducer";
import deliverySingleOrderReducer from "./Delivery/singleorder/reducer";
import gpsLocationReducer from "./Delivery/gpslocation/reducer";
import acceptToDeliver from "./Delivery/deliveryprogress/reducer";

import helperReducer from "./helper/reducer";

export default combineReducers({
	
	delivery_user: deliveryUserReducer,
	delivery_orders: deliveryOrdersReducer,
	single_delivery_order: deliverySingleOrderReducer,
	gps_location: gpsLocationReducer,
	accepted_order: acceptToDeliver,
	helper: helperReducer,
});
