import { GET_SINGLE_DELIVERY_ORDER, GET_ORDER_PICKUPS, GET_SINGLE_PICKUP,ADD_ORDER_PICKUP_PRICE,REMOVE_ORDER_PICKUP} from "./actionTypes";

const initialState = {
    single_delivery_order: [],
    pickup: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_DELIVERY_ORDER:
            return { ...state, single_delivery_order: action.payload };
        case GET_SINGLE_PICKUP:
            return { ...state, pickup: action.payload };
        case GET_ORDER_PICKUPS:
            return { ...state, single_delivery_order: action.payload };
        case ADD_ORDER_PICKUP_PRICE:
            return { ...state, single_delivery_order: action.payload };
        case REMOVE_ORDER_PICKUP:
            console.log(state,action.payload,'reducer')
            return { ...state, single_delivery_order: action.payload };       
        default:
            return state;
    }
}
