import React, { Component } from "react";
import Ink from "react-ink";
import FeatherIcon from "feather-icons-react";
class BackButtonHeader extends Component {
  static contextTypes = {
    router: () => null,
  };
  render() {
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-2'>
            <button
              type='button'
              className='btn search-navs-btns back-button'
              style={{ position: "relative" }}
              onClick={() =>
                this.props.forceExit == true
                  ? this.context.router.history.push("/")
                  : this.props.to
                  ? this.context.router.history.push(this.props.to)
                  : this.context.router.history.goBack()
              }>
              <FeatherIcon icon='chevron-left' color='#000000' />
              <Ink duration='500' />
            </button>
          </div>
          <div
            className='col-10 text-center'
            style={{ display: "flex", alignItems: "center" }}>
            <div className='header-fixed-vendor'>
              <label className='text-dark '>{this.props.title}</label>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BackButtonHeader;
