import React, { Component } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

class ProgressUpload extends Component {
  render() {
    const { status, text } = this.props;
    return (
      <div style={{ marginTop: "80%", padding: "5vw", height: "10vh" }}>
        <ProgressBar
          completed={status}
          bgColor={"#003282"}
          height={"20px"}
          labelSize={"10px"}
        />
        <h6
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {text}
        </h6>
      </div>
    );
  }
}

export default ProgressUpload;
