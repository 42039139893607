import React, { Component } from 'react';
import BackButtonHeader from '../../Elements/BackButtonHeader';
import { connect } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { ToastContainer, toast } from 'react-toastify';
import { Empty } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import { BarChartOutlined } from '@ant-design/icons'
import Loader from '../../Delivery/Loader';
import 'react-spring-bottom-sheet/dist/style.css';
import { agentRequestPayout } from "../../../services/Delivery/user/actions";
import { getAgentPayouts } from "../../../services/Delivery/user/actions";
import Moment from 'moment';
import Axios from "axios";
import { GET_DELIVERY_GUY_PAYOUT } from '../../../configs';
import { REQUEST_DELIVERY_GUY_PAYOUT } from '../../../configs';
class Payout extends Component {
    state = {
        open: false,
        close: true,
        loading: false,
        payout: [],
        amount: this.props.amount
    }
    componentDidMount() {
        const { delivery_user } = this.props;
        this.props.getAgentPayouts(delivery_user.data.auth_token);
        if (delivery_user.success === true) {
            console.log(delivery_user.success);
        }

    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
    }



    openSheet = () => {
        this.setState({
            open: true,
            close: false,
        });
    }

    onSheetClose = () => {
        this.setState({
            open: false,
            close: true,
        });
    }
    noAmount = () => {
        toast.error(localStorage.getItem('no_amount_to_request'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    onRequest = () => {

        // this.setState({ loading: true });
        const { delivery_user } = this.props;
        this.props.agentRequestPayout(delivery_user.data.auth_token);
        if (delivery_user.success === true) {
            console.log(delivery_user.success);
            this.setState({
                open: false,
                close: true,
                amount: 0

            });
            toast.success(localStorage.getItem('amount_requested_successfully'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.getAgentPayouts(delivery_user.data.auth_token);
        }




    }
    render() {
        const { success, payouts } = this.props;
        const { amount } = this.state;
        return (
            <React.Fragment>
                <BackButtonHeader title={localStorage.getItem('payout')} />
                {this.state.loading && <Loader />

                }
                <div className='container'>
                    <div className='row mb-10'>
                        <div className='col-12 text-right'>
                            <button type='button' className='btn btn-dark ' onClick={this.openSheet}>{localStorage.getItem('request_payout')}</button>
                        </div>
                    </div>
                    <h5>{localStorage.getItem('completed_payouts')}</h5>
                    <hr />
                    {payouts && payouts.length > 0
                        ? payouts.map((payout, index) =>

                            <>

                                <div className='row mb-3'>
                                    <div className='col-12' key={index}>
                                        <div className='card'>
                                            <div className='card-body text-dark pl-30 pr-30 pt-10 pb-10' style={{ border: 'none', borderRadius: '12px', backgroundColor: '#f9f9f9', MozBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)" }}>
                                                <div className="row " style={{ alignItems: 'center' }}>
                                                    <div className='col-8'>
                                                        <div className='row'>
                                                            #{payout.id}
                                                        </div>
                                                        <div className='row'>
                                                            {localStorage.getItem('processed_at')}:{Moment(payout.created_at).format('YY/MM/DD')}
                                                        </div>


                                                    </div>
                                                    <div className='col-4'>
                                                        <div className='row' >
                                                            <div className='col-12 text-right p-0'>

                                                                <button type='button' className='btn btn-success' style={localStorage.getItem('lang') && localStorage.getItem('lang')=='en'?{ backgroundColor: '#14279B', borderColor: '#14279B', color: 'white', fontWeight: '600',direction:'ltr' }:{ backgroundColor: '#14279B', borderColor: '#14279B', color: 'white', fontWeight: '600',direction:'rtl' }}>{localStorage.getItem('currency')}{payout.amount}</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </>
                        )

                        : <><Empty description={localStorage.getItem('no_completed_payout')} style={{ color: '#BBBBBB' }} /></>}







                </div>
                <ToastContainer />
                <BottomSheet
                    open={this.state.open}
                    onDismiss={this.onSheetClose}
                    header={<div className="sheetHeader text-dark" style={{ fontWeight: 600 }}>{localStorage.getItem('request_payout')}</div>}
                    snapPoints={({ maxHeight }) => 0.3 * maxHeight}
                // sibling={<div className="sheetFooter">YOUR MAIN FOOTER</div>}
                >
                    <div className="sheetBody">
                        <div className="container text-dark">

                            <div className='row pl-30 pr-30 pt-10 pl-10 mb-2' >
                                <div className='col-12' style={{ fontWeight: 500 }}>
                                    <div className='card pl-30 pr-30 pt-10 pb-10' style={{ backgroundColor: '#DEEDF0', borderRadius: '15px' }}>
                                        <div className='card-body'>
                                            <div className='row' style={{alignItems: 'center'}}>
                                                <div className='col-8' style={{alignItems: 'center'}}>
                                                    <div className='row text-center'  style={{ fontSize: '15px', color: 'black', fontWeight: '600' }}>
                                                        {localStorage.getItem('balance_earning')}
                                                    </div>
                                                    <div className='row ml-10' style={localStorage.getItem('lang')&& localStorage.getItem('lang')=='en'?{ fontSize: '25px', color: '#14279B', fontWeight: '600',direction:'ltr' }:{ fontSize: '25px', color: '#14279B', fontWeight: '600',direction:'rtl'}}>
                                                        {localStorage.getItem('currency')} {amount}
                                                    </div>
                                                </div>
                                                <div className='col-4' >
                                                    <div className='row '>
                                                        <div className='col-12 text-right'>


                                                            <span class="pl-10 pr-10 " style={{ fontSize: '30px', backgroundColor: '#14279B', color: 'white', borderRadius: '10px'}}>
                                                                <BarChartOutlined />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='row mt-20 pl-30 pr-30'>
                                <div className='col-12 text-center'>
                                    {amount > 0 ?
                                        <button type='button' className='btn btn-dark btn-block'   onClick={this.onRequest} style={{ borderRadius: '8px',}}>{localStorage.getItem('request')}</button> :
                                        <button type='button' className='btn btn-dark btn-block' onClick={this.noAmount} style={{ borderRadius: '8px' }}>{localStorage.getItem('request')}</button>
                                    }
                                </div>
                            </div>


                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    delivery_user: state.delivery_user.delivery_user,
    payouts: state.delivery_user.payouts,
    amount: state.delivery_user.amount
});

export default connect(
    mapStateToProps,
    { agentRequestPayout, getAgentPayouts }
)(Payout);