// import * as firebase from "firebase/app";
import React, { Component } from "react";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
// import { Link } from "react-router-dom";
// import Moment from "react-moment";
import axios from "axios";
// import { Spinner, Pane } from "evergreen-ui";
import { AGENT_DATA } from "../../../../configs/index";
import Timer from "../Timer";
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';
import { Link } from "react-router-dom";
import { logoutDeliveryUser } from '../../../../services/Delivery/user/actions';
import { Redirect } from "react-router";
import Axios from "axios";

class UserData extends Component {
  static contextTypes = {
    router: () => null,
  };
  state = {
    loading: true,
    agent_data: [],
    open: false,
    onDismiss: true,
  };

  openSheet = () => {
    this.setState({
      open: true,
      close: false,
    });
  }
  onSheetClose = () => {
    this.setState({
      open: false,
      close: true,
    });
  }
  componentDidMount() {
    this.setState({ agent_data: this.props.agent_data })
    // axios
    //   .post(AGENT_DATA, {
    //     token: this.props.delivery_user.auth_token,
    //   })
    //   .then((response) => {
    //     this.setState({
    //       agent_data: response.data.data,
    //       loading: false,
    //     });
    //   });
  }

  changeLanguage = () => {

    if (localStorage.getItem("lang") == "en") {
      var lang = 'ar';
    } else {
      var lang = 'en';
    }

    Axios.post(`https://fleet.howincloud.com/api/v1/${localStorage.getItem("tenant")}/get-translations`, {
      lang: lang,
    })
      .then(response => {
        if (response.data.lang) {
          const langs = response.data.lang.data;
          localStorage.setItem('lang', lang)
          langs.forEach(item => {
            localStorage.setItem(item.key, item.value);
          });
          const settings = response.data.settings;
          settings.forEach(item => {
            localStorage.setItem(item.key, item.value);
          });
        }

        window.location.reload();
      });

  }
  componentWillReceiveProps(nextProps) {
    this.setState({ agent_data: nextProps.agent_data })

  }

  render() {
    if (!this.props.check_login) {
      return (
        //redirect to login page
        <Redirect to={"/login"} />
      );
    }
    return (
      <React.Fragment>
        <div
          style={{
            background:
              "linear-gradient(135deg, #4FC8FF 0%, #4576FF 50.52%, #002A7F 100%)",
            color: "white",
            borderRadius: "0px 0px 2rem 2rem",
            padding: "2rem",
          }}
        >
          {this.state.agent_data && (
            <>
              <div style={{ display: 'flex', fontSize: "18px", justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={localStorage.getItem('lang')
                  && (localStorage.getItem('lang') == 'en' ?
                    { direction: 'ltr' } : { direction: 'rtl' })}>{localStorage.getItem('hello')} {this.state.agent_data.agent_name} !</p>
						{localStorage.getItem('multy_lang') != 0 &&
                  <button onClick={() => this.changeLanguage()} className="btn btn-sm btn-dark">{localStorage.getItem('lang') ? (localStorage.getItem('lang') == 'en' ? 'العربية' : 'English') : 'العربية'}</button>
                }
                <button onClick={this.openSheet} style={{ background: 'none', outline: 'none', border: 'none' }} >
                  <FeatherIcon icon="align-right" />
                </button>
                <BottomSheet
                  open={this.state.open}
                  onDismiss={this.onSheetClose}
                  // header={<div className="sheetHeader text-dark">SHEET HEADER</div>}
                  snapPoints={({ maxHeight }) => 0.75 * maxHeight}
                // sibling={<div className="sheetFooter">YOUR MAIN FOOTER</div>}
                >
                  <div className="sheetBody">
                    <div className=" ">
                      <div className=" mt-10" style={{ "alignItems": "center", "alignContent": "center", "flexDirection": "row", "flexWrap": "wrap", "justifyContent": "space-around", padding: '10px' }}>
                        <div className=" mb-2" style={{ width: '100%' }}>
                          <Link to="/task-history">
                            <div className="card "
                              style={{
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-3 text-center d-flex justify-content-between items-align-center">
                                <img src="assets/misc/box.png" style={{ objectFit: 'cover', height: '4vh' }} alt="leader-board" />

                                <div className="text-dark " style={{ fontSize: '15px', fontWeight: '600' }}>
                                  {localStorage.getItem('completed_and_cancelled_tasks')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className=" mb-2" style={{ width: '100%' }}>
                          <Link to="/earning-history">
                            <div className="card "
                              style={{
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-3 text-center d-flex justify-content-between items-align-center">
                                <img src="assets/misc/transaction.png" style={{ objectFit: 'cover', height: '4vh' }} alt="Transactions" />

                                <div className="text-dark " style={{ fontSize: '15px', fontWeight: '600' }}>
                                  {localStorage.getItem('earnining_and_transactions')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        {/* <div className=" mb-2" style={{ width: '100%' }}>
                          <Link to="/leader-board">
                            <div className="card "
                              style={{
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-3 text-center d-flex justify-content-between items-align-center">
                                <img src="assets/misc/leader-board.png" style={{ objectFit: 'cover', height: '4vh' }} alt="leader-board" />

                                <div className="text-dark " style={{ fontSize: '15px', fontWeight: '600' }}>
                                  {localStorage.getItem('agent_leaderboard')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                        <div className="mb-2" style={{ width: '100%' }}>
                          <Link to="/kyc-upload">
                            <div className="card "
                              style={{
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-3 text-center d-flex justify-content-between items-align-center">
                                <img src="assets/misc/kyc.png" style={{ objectFit: 'cover', height: '4vh' }} alt="leader-board" />
                                <div className="text-dark" style={{ fontSize: '15px', fontWeight: '600' }}>
                                  {localStorage.getItem('documents_and_other_details')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="mb-2" style={{ width: '100%' }}>
                          <Link to="/cod-collection-history">
                            <div className="card "
                              style={{
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-3 text-center d-flex justify-content-between items-align-center">
                                <img src="assets/misc/money.png" style={{ objectFit: 'cover', height: '4vh' }} alt="leader-board" />

                                <div className="text-dark" style={{ fontSize: '15px', fontWeight: '600' }}>
                                  {localStorage.getItem('cash_on_hand_details')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        {localStorage.getItem("hide_request_payout") != 'true' ? (<div className="mb-2" style={{ width: '100%' }}>
                          <Link to="/payout">
                            <div className="card "
                              style={{
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-3 text-center d-flex justify-content-between items-align-center">
                                <img src="assets/misc/payout.png" style={{ objectFit: 'cover', height: '4vh' }} alt="leader-board" />

                                <div className="text-dark text-center" style={{ fontSize: '15px', fontWeight: '600' }}>
                                  {localStorage.getItem('payout_details')}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>) : null}

                        {/* <div className="" style={{ width: '25%' }}>
                          <Link to="/payout">
                            <div className="card "
                              style={{
                                width: '20vw',
                                borderRadius: '15px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: '#f9f9f9'
                              }}
                            >
                              <div className="p-10 text-center">
                                <img src="assets/misc/payout.png" style={{ objectFit: 'cover', height: '4vh' }} alt="leader-board" />
                                <br />
                                <span className="text-dark pt-3" style={{ fontSize: '0.65em', fontWeight: '600' }}>
                                  Task History
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                      </div>

                      <div onClick={() => { this.props.logoutDeliveryUser(); }} className="  " style={{ "display": "flex", "alignItems": "center", "alignContent": "center", "flexDirection": "row", "flexWrap": "wrap", "justifyContent": "center", paddingBottom: '25vw' }}>
                        <div className="col-12" >
                          <div className="card bg-danger "
                            style={{
                              borderRadius: '15px',
                              border: 'none',
                              outline: 'none',
                              boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                              WebkitBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                              MozBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)"
                            }}
                          >
                            <div className="p-10 text-center" >

                              <span className="text-white pt-3" style={{ fontSize: '1.1em', fontWeight: '600' }}>
                                {localStorage.getItem('logout')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </BottomSheet>
              </div>
              <div className="">

                <p style={{ fontSize: "13px" }}>{this.props.agent_team ? this.props.agent_team.name : ''}</p>
              </div>
            </>
          )
          }
          <Timer />

          <div
            style={{
              backgroundColor: "white",
              borderRadius: "1rem",
              marginTop: "14px",
              justifyContent: "space-between",
              color: "black",
            }}
            className="d-flex"
          >
            <div
              style={{
                width: "33%",
                padding: "10px",
                textAlign: "center",
                borderRadius: "1rem",
                backgroundColor: "rgb(242, 242, 242)",
                color: "#2c6add",
                fontWeight: "600",
              }}
            >
              <Link to="/earning-history"><p> {localStorage.getItem('earnings')} </p></Link>
              {this.state.agent_data && (

                <p style={localStorage.getItem('lang') && localStorage.getItem('lang') == 'en' ? { fontSize: "16px", fontWeight: "800", direction: 'ltr' } : { fontSize: "16px", fontWeight: "800", direction: 'rtl' }}> {localStorage.getItem('currency')} {this.state.agent_data.agent_wallet_balance}  </p>
              )}

            </div>
            <div
              style={{
                width: "33%",
                padding: "10px",
                textAlign: "center",
                color: "#2c6add",
                fontWeight: "600",
              }}
            >
              <Link to="/task-history"><p> {localStorage.getItem('task')} </p></Link>
              <p style={{ fontSize: "16px", fontWeight: "800" }}> {this.state.agent_data.completed_orders_today} </p>
            </div>
            <div
              style={{
                width: "33%",
                padding: "10px",
                textAlign: "center",
                borderRadius: "1rem",
                backgroundColor: "rgb(242, 242, 242)",
                color: "#2c6add",
                fontWeight: "600",
              }}
            >
              <Link to="/cod-collection-history"><p> {localStorage.getItem('cod')} </p></Link>
              <p style={localStorage.getItem('lang') && localStorage.getItem('lang') == 'en' ? { fontSize: "16px", fontWeight: "800", direction: 'ltr' } : { fontSize: "16px", fontWeight: "800", direction: 'rtl' }}>{localStorage.getItem('currency')} {this.state.agent_data.cod_total} </p>
            </div>
          </div>
        </div >
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_user: state.delivery_user.delivery_user.data,
  check_login: state.delivery_user.delivery_user.success,
  agent_team: state.delivery_user.agent_team
});

export default connect(mapStateToProps, { logoutDeliveryUser })(UserData);
