import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./components/App";
import Loadable from "react-loadable";
import Loading from "./components/helpers/loading";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import withTracker from "./withTracker";

import NotFound from "./components/NotFound";
import 'antd/dist/antd.css';
import Profile from "./components/Delivery/Profile";
import './components/style.css';

import KycUpload from "./components/Delivery/KycUpload"
import LeaderBoard from "./components/Delivery/LeaderBoard";
import Payout from "./components/Delivery/Payout"
import CodCollectionHistory from "./components/Delivery/CodCollectionHistory";
import TaskHistory from "./components/Delivery/TaskHistory";
import EarningHistory from "./components/Delivery/EarningHistory";

/* Delivery */
// import Delivery from "./components/Delivery";
const Delivery = Loadable({
	loader: () => import("./components/Delivery"),
	loading: () => <Loading />,
});

// import DeliveryLogin from "./components/Delivery/Login";
const DeliveryLogin = Loadable({
	loader: () => import("./components/Delivery/Login"),
	loading: () => <Loading />,
});


// import ViewOrder from "./components/Delivery/ViewOrder";
const ViewOrder = Loadable({
	loader: () => import("./components/Delivery/ViewOrder"),
	loading: () => <Loading />,
});

//import anyorder from "./components/Delivery/AnyStoreOrders"

const AnyStoreOrders  =Loadable({
	loader: () => import("./components/Delivery/AnyStoreOrders"),
	loading:()=> <Loading/>
})

const ViewPickup = Loadable({
	loader: () => import("./components/Delivery/ViewPickup"),
	loading: () => <Loading />,
});

const PickupItems = Loadable({
	loader: () => import("./components/Delivery/ViewPickup/PickupItems"),
	loading: () => <Loading />,
});

const KYC = Loadable({
	loader: () => import("./components/Delivery/KYC"),
	loading: () => <Loading />,
});
const ScrollToTop = () => {
	window.scrollTo(0, 0);
	return null;
};

ReactDOM.render(
	<Root>
		<BrowserRouter>
			<React.Fragment>
				<Route component={ScrollToTop} />
				<Switch>
					{/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
					<Route path={"/"} exact component={withTracker(App)} />
					<Route path={"/set-tenant/:tenant"} exact component={withTracker(App)} />


					{/* Delivery Routes */}
					<Route path={"/orders/:page"} exact component={Delivery} />
					<Route path={"/login"} exact component={DeliveryLogin} />
					<Route path={"/order/view/:unique_order_id"} exact component={ViewOrder} />
					<Route path={"/pickup/view/:unique_order_id"} exact component={ViewPickup} />
					<Route path={"/pickup/items/:id"} exact component={PickupItems} />
					<Route path={"/completed-orders"} exact component={Delivery} />
					<Route path={"/profile"} exact component={Profile} />
					<Route path={"/kyc-upload"} exact component={KycUpload} />
					<Route path={"/leader-board"} exact component={LeaderBoard} />
					<Route path={"/task-history"} exact component={TaskHistory} />
					<Route path={"/earning-history"} exact component={EarningHistory} />

					<Route path={"/payout"} exact component={Payout} />
					<Route path={"/cod-collection-history"} exact component={CodCollectionHistory} />
					<Route path={'/any-store-order/items/:id'} exact component={AnyStoreOrders}/>

					
					{/* Common Routes */}
					<Route component={NotFound} />
				</Switch>
			</React.Fragment>
		</BrowserRouter>
	</Root>,
	document.getElementById("root")
);
