import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

class CustomCssProvider extends Component {
	render() {
		return (
			<React.Fragment>
						{localStorage.getItem("deliveryAppLightMode") === "true" && (
					<Helmet>
						<link rel="stylesheet" type="text/css" href="https://najiya.howincloud.com/fleet/assets/delivery-app-light.css" />
						<link rel="stylesheet" type="text/css" href="https://najiya.howincloud.com/fleet/assets/main.css" />
						<link rel="stylesheet" type="text/css" href="https://najiya.howincloud.com/fleet/assets/custom.css" />
					</Helmet>
				 )}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
});

export default connect(
	mapStateToProps,
	{}
)(CustomCssProvider);
