import React, { Component } from 'react';
import { connect } from "react-redux";
import { getTopAgents } from "../../../services/Delivery/user/actions";
import BackButtonHeader from '../../Elements/BackButtonHeader';
import ProgressiveImage from "react-progressive-image";

class LeaderBoard extends Component {

    state = {
        globalLeads: false,
        teamLeads: true,
        WEBSITE_URL: 'https://fleet.howincloud.com/'
    }
    componentDidMount() {
        const { delivery_user } = this.props;
        this.props.getTopAgents(delivery_user.data.auth_token);
    }


    showTeamLead = () => {
        this.setState({
            globalLeads: false,
            teamLeads: true,
        })
    }
    showGlobalLead = () => {
        this.setState({
            globalLeads: true,
            teamLeads: false,
        })
    }

    __returnRankDivImage = (index) => {
        switch (index + 1) {
            case 1: return <img src="../../../assets/misc/trophy1-badge.png" style={{ objectFit: 'cover', height: '3vh', }} alt="trophy" />;
                break;
            case 2: return <img src="../../../assets/misc/trophy2-badge.png" style={{ objectFit: 'cover', height: '3vh', }} alt="trophy" />;
                break;
            case 3: return <img src="../../../assets/misc/trophy3-badge.png" style={{ objectFit: 'cover', height: '3vh', }} alt="trophy" />;
                break;
            default: return <span className=" badge  text-white  " style={{ position: 'absolute', top: '-2px', backgroundColor: '#F27000', left: '2px', borderRadius: '30px' }} >{index + 1}</span>

                break;
        }
    }
    __returnRankDivColor = (index) => {
        switch (index + 1) {
            case 1: return 'linear-gradient(to right, #FFD73F, #FFE975)'
                break;
            case 2: return 'linear-gradient(to right, #D4D5C5, #DEDFCF)'
                break;
            case 3: return 'linear-gradient(to right, #F4AA6B, #F5B379)'
                break;
            default: return 'linear-gradient(to right, #F9F9F9, #F9F9F9)'
                break;
        }
    }
    render() {
        const { global_points, user_ranks, agent_team, team_ranked_agents } = this.props;

        if(team_ranked_agents.length > 0 && global_points.length > 0 && agent_team){
            return (
                <React.Fragment>
                    <BackButtonHeader title={localStorage.getItem('leader_board')} />
                    <div className="container" style={{ height: '180Fvh' }}>
                        <div className="row">
                            <div className="col-12 justify-content-center">
                                <btn className="btn-group" aria-label="Basic example">
                                    {agent_team ?
                                        <button className="btn btn-dark " onClick={this.showTeamLead} style={this.state.teamLeads ? { borderRadius: '20px 20px 20px 20px', left: '10px' } : { borderRadius: '20px 0px 0px 20px', borderRight: 'none ', backgroundColor: '#ffffff', color: '#000000' }} >
                                            {agent_team.name}
                                        </button>
                                        :
                                        <button className="btn btn-oultine-dark" >{localStorage.getItem('not_assigned_any_team')}</button>
                                    }
                                    <button className="btn btn-dark  " onClick={this.showGlobalLead} style={this.state.globalLeads ? { borderRadius: '20px 20px 20px 20px', right: '10px' } : { borderRadius: '0px 20px 20px 0px', borderLeft: 'none', backgroundColor: '#ffffff', color: '#000000' }}>{localStorage.getItem('global_rank')}</button>
                                </btn>
    
                                {/* style={state ? {} : {}} */}
                            </div>
                        </div>
                        {/* 'linear-gradient(to right, #fdbf19, #fee5a1)' */}
                        {this.state.globalLeads && !this.state.teamLeads && global_points && global_points.length > 0
                            ? global_points.map((global_point, index) => <div class="row mt-2">
                                <div key={index} className="col-12">
                                    <div class="card " style={{ borderRadius: '10px' }} >
                                        <div className="p-15" style={{ backgroundImage: this.__returnRankDivColor(index), borderRadius: '10px' }}>
                                            <div className="" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                                <ProgressiveImage
                                                    delay={100}
                                                    src={this.state.WEBSITE_URL + global_point.agent.image}
                                                    placeholder={'https://cdn.eattoss.com/assets/pattern.png'}
                                                >
                                                    {(src) => (
    
                                                        <img src={src} alt="fleet" style={{ verticalAlign: 'middle', width: '15vw', height: '7vh', borderRadius: '15%', objectFit: 'cover' }} />
                                                    )}
    
                                                </ProgressiveImage>
                                                <div className="" style={{ position: 'absolute', top: '11px', left: '22px' }}>
                                                    {this.__returnRankDivImage(index)}
                                                </div>
                                                <span className="text-dark" style={{ fontSize: '0.95em', fontWeight: '600' }}>{global_point.agent.name}</span>
    
                                                <span className="text-dark" style={{ fontSize: '1em', fontWeight: '600' }}><img src="assets/misc/point.png" style={{ height: '3vh', width: '6vw', objectFit: 'cover' }} alt="fleet" />&nbsp;&nbsp;{global_point.point}  <span className="font-size-sm">pts</span></span>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </div>)
                            : <span></span>}
    
                        {this.state.teamLeads && !this.state.globalLeads && team_ranked_agents && team_ranked_agents.length > 0
                            ? team_ranked_agents.map((team_ranked_agent, index) => 
                            
                            <div class="row mt-2">
                                <div key={index} className="col-12">
                                    <div class="card " style={{ borderRadius: '10px' }} >
                                        <div className="p-15" style={{ backgroundImage: this.__returnRankDivColor(index), borderRadius: '10px' }}>
                                            <div className="" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                                <ProgressiveImage
                                                    delay={100}
                                                    src={this.state.WEBSITE_URL + team_ranked_agent.agent.image}
                                                    placeholder={'https://cdn.eattoss.com/assets/pattern.png'}
                                                >
                                                    {(src) => (
    
                                                        <img src={src} alt="fleet" style={{ verticalAlign: 'middle', width: '15vw', height: '7vh', borderRadius: '15%', objectFit: 'cover' }} />
                                                    )}
    
                                                </ProgressiveImage>
                                                <div className="" style={{ position: 'absolute', top: '11px', left: '22px' }}>
                                                    {this.__returnRankDivImage(index)}
                                                </div>
                                                <span className="text-dark" style={{ fontSize: '0.95em', fontWeight: '600' }}>{team_ranked_agent.agent.name}</span>
    
                                                <span className="text-dark" style={{ fontSize: '1em', fontWeight: '600' }}><img src="assets/misc/point.png" style={{ height: '3vh', width: '6vw', objectFit: 'cover' }} alt="fleet" />&nbsp;&nbsp;{team_ranked_agent.point}  <span className="font-size-sm">pts</span></span>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) :
                            <span></span>
                        }
    
                    </div>
                    {
                        user_ranks
                            ?
                            <div className=" footer-fixed-rank">
                                <div className="card " >
                                    <div className="p-15" style={{ backgroundColor: '#cbb48b', borderRadius: '25px 25px 0px 0px' }}>
                                        <div className="" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                            {user_ranks.agent.image ?
    
                                                <ProgressiveImage
                                                    delay={100}
                                                    src={this.state.WEBSITE_URL + user_ranks.agent.image}
                                                    placeholder={'https://cdn.eattoss.com/assets/pattern.png'}
                                                >
                                                    {(src, loading) => (
    
                                                        <img src={src} alt="fleet" style={{ verticalAlign: 'middle', width: '15vw', height: '7vh', borderRadius: '15%', objectFit: 'cover' }} />
                                                    )}
    
                                                </ProgressiveImage>
                                                :
                                                <img src="#" alt="fleet" style={{ verticalAlign: 'middle', width: '15vw', height: '7vh', borderRadius: '15%', objectFit: 'cover' }} />
    
                                            }
                                            <span className=" badge badge-light text-dark" style={{ position: 'absolute', top: '10px', left: '7px', backgroundColor: '#ffffff', borderRadius: '35px' }} >4</span>
                                            <span className="text-dark" style={{ fontSize: '1em', fontWeight: '600' }}>{user_ranks.agent.name}</span>
                                            <span className="text-dark" style={{ fontSize: '1em', fontWeight: '600' }}><img src="assets/misc/point.png" style={{ height: '3vh', width: '6vw', objectFit: 'cover' }} alt="fleet" />&nbsp;&nbsp;{user_ranks.point}  <span className="font-size-sm">pts</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            : <h1>uyguyg</h1>
                    }
    
                </React.Fragment >
            );
        }else{
            return null;
        }
        
    }
}

const mapStateToProps = (state) => ({
    delivery_user: state.delivery_user.delivery_user,
    global_points: state.delivery_user.global_points,
    user_ranks: state.delivery_user.user_ranks,
    agent_team: state.delivery_user.agent_team,
    team_ranked_agents: state.delivery_user.team_ranked_agents,
});

export default connect(
    mapStateToProps,
    { getTopAgents }
)(LeaderBoard);

