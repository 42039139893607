import React, { Component } from "react";
import BackButtonHeader from "../../Elements/BackButtonHeader";
import Axios from "axios";
import { Input, Button } from "antd";
import Ink from "react-ink";
import Loader from "../../Delivery/Loader";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Modal from "react-responsive-modal";
import "react-responsive-modal/src/styles.css";
import { Alert } from "antd";

import FeatherIcon from "feather-icons-react";

import { updateDeliveryUserInfo } from "../../../services/Delivery/user/actions";
import { connect } from "react-redux";
import { UPLOAD_ADHAR_FRONT_URL } from "../../../configs";
import { UPLOAD_ADHAR_BACK_URL } from "../../../configs";
import { UPLOAD_PAN_FRONT_URL } from "../../../configs";
import { UPLOAD_PAN_BACK_URL } from "../../../configs";
import { UPLOAD_DRIVING_LISENCE_URL } from "../../../configs";
import { SAVE_BANKING_DETAILS_URL } from "../../../configs";
import { UPDATE_KYC_URL } from "../../../configs";
import { GET_KYC_DATA_URL } from "../../../configs";
import { CloudUploadOutlined, SettingFilled } from "@ant-design/icons";
import ImgsViewer from "react-images-viewer";

import { flex } from "ui-box";
import {
  saveAadharBack,
  saveAadharFront,
  saveLicense,
  saveLicenseBack,
  savePan,
  saveSmokeTest,
  saveVehicleInsurance,
  saveVehicleRc,
} from "./kycUpload";
import ProgressUpload from "./ProgressUpload";
import BankingDetails from "./BankingDetails";

class KycUpload extends Component {
  state = {
    active_personal: true,
    active_banking: false,
    adharFront: null,
    adharBack: null,
    panFront: null,
    panBack: null,
    drivingLicense: null,
    drivingLicenseBack: null,
    smokeTest: null,
    loading: false,
    openModal: false,
    vehicle_model: null,
    address: null,
    vehicleRc: null,
    vehicleInsurance: null,
    //state for showing modal when license or vehicle number is null
    show_modal: false,
    kyc_agent_data: [],
    percentage: 0,
    progress_text: "",
    userImage: null,
    userImageSelected: false,
  };
  componentDidMount() {
    this.setState({ loading: true });
    console.log(this.props.delivery_user.data.auth_token);
    this.getAgentKycData();
  }

  getAgentKycData = () => {
    Axios.post(GET_KYC_DATA_URL, {
      token: this.props.delivery_user.data.auth_token,
    }).then((response) => {
      this.setState({
        kyc_agent_data: response.data,
      });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 100);
    });
  };
  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleInputVehicleModel = (event) => {
    this.setState({ vehicle_model: event.target.value });
  };

  handleInputAddress = (event) => {
    this.setState({ address: event.target.value });
  };

  onUpdate = () => {
    this.setState({ loading: true });
    if (this.state.address && this.state.vehicle_model) {
      Axios.post(UPDATE_KYC_URL, {
        token: this.props.delivery_user.data.auth_token,
        model: this.state.vehicle_model,
        address: this.state.address,
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          this.setState({ loading: false, openModal: true });
        }
      });
    } else {
      this.setState({ show_modal: true });
    }
  };

  onBanKDetails = (holder, account, ifsc, bank, upi) => {
    if (holder || account || ifsc || bank || upi) {
      this.setState({ loading: true });
      let token = this.props.delivery_user.data.auth_token;
      Axios.post(SAVE_BANKING_DETAILS_URL, {
        token,
        holder,
        account,
        ifsc,
        bank,
        upi,
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          this.setState({ loading: false, openModal: true });
        }
        setTimeout(() => {
          window.location.reload();
        }, 100);
      });
    }
  };
  onAdharFront = (event) => {
    this.setState({ adharFront: event.target.files[0] });
    console.log(this.state.adharFront);
  };

  onAdharFrontUpload = () => {
    if (this.state.adharFront) {
      this.setState({
        progress_text:
          "{localStorage.getItem('uploading_aadhar_front_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.adharFront);
      formData.append("token", this.props.delivery_user.data.auth_token);
      saveAadharFront(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };

  onAdharBack = (event) => {
    this.setState({ adharBack: event.target.files[0] });
  };

  onAdharBackUpload = () => {
    if (this.state.adharBack) {
      this.setState({
        progress_text:
          "{localStorage.getItem('uploading_aadhar_back_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.adharBack);
      formData.append("token", this.props.delivery_user.data.auth_token);

      saveAadharBack(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };

  onPanFront = (event) => {
    this.setState({ panFront: event.target.files[0] });
  };

  onPanFrontUpload = () => {
    if (this.state.panFront) {
      this.setState({
        progress_text: "{localStorage.getItem('uploading_pan_card')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.panFront);
      formData.append("token", this.props.delivery_user.data.auth_token);
      savePan(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };

  onSmokeTest = (event) => {
    this.setState({ smokeTest: event.target.files[0] });
  };

  onSmokeTestUpload = () => {
    if (this.state.smokeTest) {
      this.setState({
        progress_text:
          "{localStorage.getItem('uploading_vehicle_smoke_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.smokeTest);
      formData.append("token", this.props.delivery_user.data.auth_token);

      saveSmokeTest(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };

  onDrivingLisence = (event) => {
    this.setState({ drivingLicense: event.target.files[0] });
  };

  onDrivingLisenceUpload = () => {
    if (this.state.drivingLicense) {
      this.setState({
        progress_text:
          "{localStorage.getItem('uploading_driving_license_front_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.drivingLicense);
      formData.append("token", this.props.delivery_user.data.auth_token);
      saveLicense(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };
  onDrivingLisenceBack = (event) => {
    this.setState({ drivingLicenseBack: event.target.files[0] });
  };
  onDrivingLisenceBackUpload = () => {
    if (this.state.drivingLicenseBack) {
      this.setState({
        progress_text:
          "{localStorage.getItem('uploading_driving_license_back_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.drivingLicenseBack);
      formData.append("token", this.props.delivery_user.data.auth_token);
      saveLicenseBack(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };

  onVehicleRc = (event) => {
    this.setState({ vehicleRc: event.target.files[0] });
  };
  onVehicleRcUpload = () => {
    if (this.state.vehicleRc) {
      this.setState({
        progress_text: "{localStorage.getItem('uploading_vehicle_rc_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.vehicleRc);
      formData.append("token", this.props.delivery_user.data.auth_token);
      saveVehicleRc(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };

  onVehicleInsurance = (event) => {
    this.setState({ vehicleInsurance: event.target.files[0] });
  };
  onVehicleInsuranceUpload = () => {
    if (this.state.vehicleInsurance) {
      this.setState({
        progress_text:
          "{localStorage.getItem('uploading_vehicle_insurance_message')}",
      });
      const formData = new FormData();
      formData.append("content", this.state.vehicleInsurance);
      formData.append("token", this.props.delivery_user.data.auth_token);
      saveVehicleInsurance(formData, (progressEvent) => {
        const percentage = parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );

        this.setState({
          percentage: percentage,
          loading: false,
          openModal: false,
        });

        if (percentage === 100) {
          this.setState({
            percentage: 0,
            loading: false,
            openModal: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
        return percentage; // Because you were returning the percentage before.
      });
    }
  };
  onUserImage = (event) => {
    if (event.target.files.length >= 1) {
      
      this.setState({ userImage: event.target.files[0] ,userImageSelected:true});
    } else{
      this.setState({ userImageSelected: false});

    }
  };
  render() {
    return (
      <React.Fragment>
        {this.state.loading ? (
          <Loader />
        ) : (
          this.state.kyc_agent_data &&
          this.state.kyc_agent_data.agent &&
          (this.state.percentage > 0 ? (
            <React.Fragment>
              <div
                style={{
                  height: "90vh",
                  backgroundColor: "#fff",
                  flex: 1,
                  overflow: "hidden",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ProgressUpload
                  status={this.state.percentage}
                  text={this.state.progress_text}
                />
              </div>
            </React.Fragment>
          ) : (
            <>
              <Modal
                open={this.state.openModal}
                onClose={this.onCloseModal}
                center
                style={{ borderRadius: "10px" }}
              >
                <div
                  className="container mt-4 "
                  style={{ width: "100%", height: "auto" }}
                >
                  <div
                    className=""
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <FeatherIcon
                      icon="check-circle"
                      fontSize="large"
                      color="#6ECB63"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <div className="text-center mt-2">
                    <span className="text-dark">{localStorage.getItem('success')}</span>
                  </div>
                </div>
              </Modal>

              <Modal
                open={this.state.show_modal}
                style={{ borderRadius: "10px" }}
              >
                <div
                  className="container mt-4 "
                  style={{ width: "100%", height: "auto" }}
                >
                  <span>{localStorage.getItem('data_is_mandatory')}</span>
                </div>
              </Modal>

              <BackButtonHeader title={localStorage.getItem('kyc_documents')} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  background: "#003282",
                  height: "60px",
                  margin: "15px",
                  borderRadius: "15px",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <div
                  onClick={() =>
                    this.setState({
                      active_personal: true,
                      active_banking: false,
                    })
                  }
                  style={{
                    backgroundColor: this.state.active_personal ? "#fff" : null,
                    height: "50px",
                    borderRadius: "10px",
                    width: "50%",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    color: this.state.active_personal ? "#000" : "#fff",
                    fontWeight: "700",
                  }}
                >
                  {localStorage.getItem('personal_details')}
                </div>
                <div
                  onClick={() =>
                    this.setState({
                      active_personal: false,
                      active_banking: true,
                    })
                  }
                  style={{
                    backgroundColor: this.state.active_banking ? "#fff" : null,
                    height: "50px",
                    borderRadius: "10px",
                    width: "50%",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    color: this.state.active_banking ? "#000" : "#fff",
                    fontWeight: "700",
                  }}
                >
                  {localStorage.getItem('banking_details')}
                </div>
              </div>
              {this.state.active_personal && (
                <>
                  {this.state.kyc_agent_data.agent.image && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "100px",
                          overflow: "hidden",
                          width: "100px",
                          borderRadius: "50px",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.image
                          }
                          alt="aadhar_card"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          textTransform: "capitalize",
                          color: "#000",
                          fontWeight: "700",
                        }}
                      >
                        <div>{this.state.kyc_agent_data.name}</div>
                        <div>{this.state.kyc_agent_data.phone}</div>
                        <label
                          style={{
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "40vw",
                            color: "#000",
                            marginTop: "10px",
                          }}
                          for="file-upload-adh-back"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Edit
                          Photo
                        </label>
                        <input
                          required
                          id="file-upload-adh-back"
                          type="file"
                          onChange={this.onUserImage}
                        />
                      </div>
                      <button
                        onClick={this.onAdharFrontUpload}
                        className="btn btn-block text-center pt-5"
                        style={{
                          fontWeight: "600",
                          color: "white",
                          backgroundColor: "#003282",
                          borderRadius: "5px",
                          height: "35px",
                          width: "30vw",
                        }}
                      >
                        {this.state.kyc_agent_data.agent.aadhar_card
                          ?
                          (
                            <>{localStorage.getItem('re_upload')}</>)
                          : (<>{localStorage.getItem('upload')} </>
                          )}
                      </button>
                    </div>
                  )}
                  <div style={{ height: "110vh" }}>
                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      ID Card Front
                    </label>
                    {this.state.kyc_agent_data.agent.aadhar_card && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.aadhar_card
                          }
                          alt="aadhar_card"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-adh-back"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-adh-back"
                          type="file"
                          onChange={this.onAdharFront}
                        />
                        <button
                          onClick={this.onAdharFrontUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.aadhar_card
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>
                    {/* <hr style={{ marginLeft: "15px", marginRight: "15px" }} /> */}
                    <label
                      className="col-12 mt-5 text-dark"
                      style={{
                        " color": "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      ID Card Back
                    </label>
                    {this.state.kyc_agent_data.agent.aadhar_card_back && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.aadhar_card_back
                          }
                          alt="aadhar_card_back"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload"
                          type="file"
                          onChange={(e) => this.onAdharBack(e)}
                        />
                        <button
                          onClick={this.onAdharBackUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.aadhar_card_back
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>

                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Pan Card Front
                    </label>
                    {this.state.kyc_agent_data.agent.pan_front && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.pan_front
                          }
                          alt="pan_front"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-pan"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-pan"
                          type="file"
                          onChange={(e) => this.onPanFront(e)}
                        />
                        <button
                          onClick={this.onPanFrontUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.pan_front
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>

                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Driving Lisence
                    </label>
                    {this.state.kyc_agent_data.agent.driving_licence && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.driving_licence
                          }
                          alt="driving_licence"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-drive"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-drive"
                          type="file"
                          onChange={(e) => this.onDrivingLisence(e)}
                        />
                        <button
                          onClick={this.onDrivingLisenceUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.driving_licence
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>

                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Driving Lisence Back
                    </label>
                    {this.state.kyc_agent_data.agent.driving_licence_back && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.driving_licence_back
                          }
                          alt="driving_licence_back"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-drive-back"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-drive-back"
                          type="file"
                          onChange={(e) => this.onDrivingLisenceBack(e)}
                        />
                        <button
                          onClick={this.onDrivingLisenceBackUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.driving_licence_back
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>
                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Smoke Test
                    </label>
                    {this.state.kyc_agent_data.agent.smoke_test && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.smoke_test
                          }
                          alt="smoke_test"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-smoke"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-smoke"
                          type="file"
                          onChange={(e) => this.onSmokeTest(e)}
                        />
                        <button
                          onClick={this.onSmokeTestUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.smoke_test
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>

                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Vehicle RC
                    </label>
                    {this.state.kyc_agent_data.agent.vehicle_rc && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.vehicle_rc
                          }
                          alt="	vehicle_rc"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-rc"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-rc"
                          type="file"
                          onChange={(e) => this.onVehicleRc(e)}
                        />
                        <button
                          onClick={this.onVehicleRcUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.vehicle_rc
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>

                    <label
                      className="col-12 mt-5"
                      style={{
                        color: "black",
                        fontSize: "13px",
                        fontWeight: "600",
                        letterSpacing: "1px",
                      }}
                    >
                      Vehicle Insurance
                    </label>
                    {this.state.kyc_agent_data.agent.vehicle_insurance && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #003282",
                          margin: "15px",
                          padding: "5px",
                          borderRadius: "10px",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={
                            "https://fleet.howincloud.com/" +
                            this.state.kyc_agent_data.agent.vehicle_insurance
                          }
                          alt="vehicleInsurance"
                          style={{
                            borderRadius: "10px",
                            objectFit: "cover",
                            height: "180px",
                            width: "500px",
                          }}
                        />
                      </div>
                    )}
                    <div className="mt-5 ml-15 mr-15">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          style={{
                            marginBottom: "1.5rem",
                            border: "1px solid #D9D9D9",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            fontFamily: "poppins !important",
                            width: "55vw",
                            color: "#000",
                          }}
                          for="file-upload-vehicleInsurance"
                          className="custom-file-upload"
                        >
                          <CloudUploadOutlined twoToneColor="#00b300" /> Choose
                          File
                        </label>
                        <input
                          required
                          id="file-upload-vehicleInsurance"
                          type="file"
                          onChange={(e) => this.onVehicleInsurance(e)}
                        />
                        <button
                          onClick={this.onVehicleInsuranceUpload}
                          className="btn btn-block text-center pt-5"
                          style={{
                            fontWeight: "600",
                            color: "white",
                            backgroundColor: "#003282",
                            borderRadius: "5px",
                            height: "35px",
                            width: "30vw",
                          }}
                        >
                          {this.state.kyc_agent_data.agent.vehicleInsurance
                            ? "Re-Upload"
                            : "Upload"}
                        </button>
                      </div>
                    </div>

                    <div className="mt-2 ml-15 mr-15">
                      <div className="form-group mt-2">
                        <label
                          className="form-label "
                          style={{ color: "#000000" }}
                        >
                          Vehicle Model:
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          size="large"
                          required
                          onChange={this.handleInputVehicleModel}
                          name="vehicle_model"
                          placeholder="Enter Vehicle Model"
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label
                          className="form-label "
                          style={{ color: "#000000" }}
                        >
                          Address:
                        </label>
                        <textarea
                          className="form-control"
                          size="large"
                          required
                          onChange={this.handleInputAddress}
                          name="address"
                        ></textarea>
                      </div>
                    </div>

                    <div className="container update-button-bottom mb-5">
                      <div class="text-center">
                        <button
                          onClick={this.onUpdate}
                          className="btn btn-block"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#003282",
                            border: "1px solid #003282",
                            color: "white",
                            height: "40px",
                          }}
                        >
                          <b>Update</b>
                        </button>
                      </div>
                    </div>
                    <div style={{ height: "100px" }}></div>
                  </div>
                </>
              )}
              {this.state.active_banking && (
                <BankingDetails
                  onBanKDetails={this.onBanKDetails}
                  user={this.state.kyc_agent_data}
                />
              )}
            </>
          ))
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_user: state.delivery_user.delivery_user,
});

export default connect(mapStateToProps, { updateDeliveryUserInfo })(KycUpload);
