import React, { Component } from "react";
// import { Redirect } from "react-router";
import { connect } from "react-redux";
// import FeatherIcon from "feather-icons-react";
// import { Link } from "react-router-dom";
// import Ink from "react-ink";
// import Moment from "react-moment";
// import axios from "axios";
import { Spinner, Pane } from "evergreen-ui";
// import { Popconfirm, message, Button } from "antd";

// import Meta from "../../helpers/meta";
import "../../helpers/style.css";
// import Header from "../Header";
import Footer from "../Footer";
// import Timer from "./Timer";
import UserData from "./UserData";
import OrderChart from "./OrderChart";
import { Alert } from "antd";
import { RightCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Axios from "axios";
import { AGENT_DATA, REST_INTERVAL } from "../../../configs";
import toast, { Toaster } from 'react-hot-toast';

class Profile extends Component {
  state = {
    agent_data: [],
    orders: [],
    in_loader: false,
  };
  componentDidMount() {
    this.loadAgentData();
  }

  loadAgentData = () => {
    Axios.post(AGENT_DATA, {
      token: this.props.delivery_user.auth_token,
    }).then((response) => {
      this.setState({
        agent_data: response.data.data,
        loading: false,
      });

      localStorage.setItem(
        "show_details_before_del",
        response.data.data.show_details_before_del
      );
    });
  }
  
  loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
  }

  finalizeCodCollection = async (result,data) => {
    const finalResult = await Axios.post(`https://fleet.howincloud.com/api/v1/${localStorage.getItem("tenant")}/delivery/payment-log/cod-collect/mark-as-complete`, {
            token: this.props.delivery_user.auth_token,
            data: data,
            payment_log_id: result.data.payment_log.id
          }).then((response) => {

            if(response.data.success){
              this.loadAgentData();
              toast.success("Success, COD Settled");
            }else{
              
              toast.error("Something went wrong !");

            }
          });
  }

    // notify = (data) => {
      
    // }
   displayRazorpay = async () => {

    // this.notify("Success, Wallet deduction");

    // return;
    const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        // alert("Razorpay SDK failed to load. Are you online?");
        toast.error("Razorpay SDK failed to load. Are you online?");
        return;
    }

    // creating a new order
    const result = await Axios.post(`https://fleet.howincloud.com/api/v1/${localStorage.getItem("tenant")}/delivery/payment-log/cod-collect`, {
      token: this.props.delivery_user.auth_token
    });
    

    if (!result) {
        // alert("Server error. Are you online?");
        // this.notify("Server error. Are you online?");
        toast.error("Server error. Are you online?");

        return;
    }

    if(result.data.success && result.data.operation == 'complete_wallet_deduction'){
      this.loadAgentData();
      toast.success("Success, Wallet deduction");

      return;
    }
   

    // Getting the order details back
    // const { amount, id: order_id, currency, } = result.data;

    const options = {
        key: result.data.payment_public_key, // Enter the Key ID generated from the Dashboard
        // amount: result.data.payment_log.toString(),
        // currency: currency,
        // name: "Soumya Corp.",
        // description: "Test Transaction",
        order_id: result.data.payment_log?.pg_refrence_id,
        handler: async (response) => {  
            const data = {
                orderCreationId: result.data.payment_log?.pg_refrence_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };

          // creating a new order
          
            this.finalizeCodCollection(result,data);


        },
        prefill: {
            name: "Fleet",
            email: "customer@howinfleet.com",
            contact: "6238784731",
        },
     
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}

  resetInterval = () => {
    this.setState({ in_loader: true });

    Axios.post(REST_INTERVAL, {
      token: this.props.delivery_user.auth_token,
    }).then((response) => {
      Axios.post(AGENT_DATA, {
        token: this.props.delivery_user.auth_token,
      }).then((response) => {
        this.setState({
          agent_data: response.data.data,
          in_loader: false,
        });
      });
    });
  };

  render() {
    return (
      <React.Fragment>

        {this.state.in_loader === true && (
          <Pane
            display='flex'
            alignItems='center'
            justifyContent='center'
            height={500}>
            <Spinner />
          </Pane>
        )}
        <UserData agent_data={this.state.agent_data} />
        <div
          style={{
            padding: "1rem",
          }}>
          {!this.state.agent_data.is_kyc && (
            <Link to='/kyc-upload'>
              <Alert
                type='warning'
                banner
                message={
                  <div className='d-flex justify-content-between'>
                    <p>{localStorage.getItem("your_kyc_is_pending")} </p>
                    <p>
                      <RightCircleTwoTone />
                    </p>
                  </div>
                }
              />
            </Link>
          )}
        </div>{" "}
        {(this.state.agent_data.cod_total > 0 && localStorage.getItem("cod_online") == "true") && (
          <div
          style={{
            padding: "1rem",
        }}>
         
              <div
                onClick={() => this.displayRazorpay()}
                style={{
                  top: "3rem",
                  width: "100%",
                  background: "rgb(255, 84, 84)",
                  height: "4rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "16px",
                  borderRadius: "14px",
                }}>
                Settle COD {localStorage.getItem('currency')}  {parseFloat(this.state.agent_data.cod_total).toFixed(2) ?? '0.00'} Online
              </div>
            
        </div>
        ) }
        
        {this.state.agent_data.agent_perfomance && (
          <div style={{"color":"rgb(17, 103, 163)","alignItems":"center","display":"flex","flexFlow":"column","rowGap":"7px"}}>
            <div
              style={{"width":"90%","textAlign":"center","backgroundColor":"white","padding":"10px 18px","borderRadius":"9px","display":"flex","placeContent":"center space-between","alignItems":"center","boxShadow":"rgba(0, 42, 127, 0.05) 0px 1px 6px"}}>
              <div>{localStorage.getItem("today_points")} </div>
              <div className='font-weight-bold' style={{ fontSize: "18px" }}>
                {this.state.agent_data.agent_perfomance.total_point}
              </div>
            </div>
            {localStorage.getItem("show_first_mile_earnings") == "true" && (
              <div
                style={{"width":"90%","textAlign":"center","backgroundColor":"white","padding":"10px 18px","borderRadius":"9px","display":"flex","placeContent":"center space-between","alignItems":"center","boxShadow":"rgba(0, 42, 127, 0.05) 0px 1px 6px"}}>
                <div>{localStorage.getItem("first_mile_earning")}</div>
                <div className='font-weight-bold' style={{ fontSize: "18px" }}>
                {localStorage.getItem('currency')}   {parseFloat(this.state.agent_data.fm_earning).toFixed(2) ?? '0.00'}
                </div>
              </div>
            )}
             {localStorage.getItem("show_fine_amount") == "true" && (
              <div
                style={{"width":"90%","textAlign":"center","backgroundColor":"white","padding":"10px 18px","borderRadius":"9px","display":"flex","placeContent":"center space-between","alignItems":"center","boxShadow":"rgba(0, 42, 127, 0.05) 0px 1px 6px"}}>
                <div>{localStorage.getItem("fine_amount")}</div>
                <div className='font-weight-bold' style={{ fontSize: "18px" }}>
                {localStorage.getItem('currency')}   {parseFloat(this.state.agent_data.agent_perfomance.fine_amount).toFixed(2) ?? '0.00'}
                </div>
              </div>
            )}
            {localStorage.getItem("show_travel_record") == "true" && (
              <div
                style={{"width":"90%","textAlign":"center","backgroundColor":"white","padding":"10px 18px","borderRadius":"9px","display":"flex","placeContent":"center space-between","alignItems":"center","boxShadow":"rgba(0, 42, 127, 0.05) 0px 1px 6px"}}>
                <div>{localStorage.getItem("today_travel")}</div>
                <div className='font-weight-bold' style={{ fontSize: "18px" }}>
                  {this.state.agent_data.agent_perfomance.distance_travelled} KM
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.agent_data.chart_data && (
          <OrderChart chart_data={this.state.agent_data.final_chart_data} />
        )}
        <br />
        <br />
        <br />
        <Footer current_page={"profile"} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_user: state.delivery_user.delivery_user.data,
});

export default connect(mapStateToProps)(Profile);
