
import React, { Component } from "react";

import Meta from "../helpers/meta";
import Orders from './Orders';
import '../helpers/style.css';
import Header from "./Header";
import Footer from './Footer';

import { Redirect } from "react-router";
import { connect } from "react-redux";
import DeliveryDesktop from "./DeliveryDesktop";

class Delivery extends Component {

	state = {
		status: localStorage.getItem('status'),
	}
	async componentDidMount() {
		if (document.querySelector("#mainManifest")) {
			document.querySelector("#mainManifest").setAttribute("href", "/delivery-manifest.json");
		}
		if (document.getElementsByTagName("body")) {
			document.getElementsByTagName("body")[0].classList.remove("bg-grey");
			document.getElementsByTagName("body")[0].classList.add("bg-white");
		}

		const { delivery_user } = this.props;


	}

	setJobStatus = (status) => {
		this.setState({status: status});
		localStorage.setItem('on_duty', status);
	}

	render() {
		if (window.innerWidth > 768) {
			return <DeliveryDesktop />;
		}
		const { delivery_user } = this.props;

		if (!delivery_user.success) {
			return <Redirect to={"/login"} />;
		}
		return (
			<React.Fragment>
				<Meta
					seotitle="Delivery Orders"
					seodescription={localStorage.getItem("seoMetaDescription")}
					ogtype="website"
					ogtitle={localStorage.getItem("seoOgTitle")}
					ogdescription={localStorage.getItem("seoOgDescription")}
					ogurl={window.location.href}
					twittertitle={localStorage.getItem("seoTwitterTitle")}
					twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>
				<Header setJobStatus={this.setJobStatus} />
				<div className="w-100 mt-100 pb-100">
					<Orders data_core={this.props.page ? this.props.page : this.props.match.params.page} job_status={this.state.status} />
				</div>
				<Footer current_page={this.props.page ? this.props.page : this.props.match.params.page} />

				{/* {console.log(this.props.page)} */}

			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	delivery_user: state.delivery_user.delivery_user,
});

export default connect(
	mapStateToProps,
)(Delivery);
