import { WEBSITE_URL } from "./website";

/* Delivery URLs */
export const LOGIN_DELIVERY_USER_URL = WEBSITE_URL + "/login";
export const UPDATE_DELIVERY_USER_INFO_URL = WEBSITE_URL + "/update-user-info";
export const GET_DELIVERY_ORDERS_URL = WEBSITE_URL + "/get-delivery-orders";
export const GET_SINGLE_DELIVERY_ORDER_URL =
  WEBSITE_URL + "/get-single-delivery-order";
export const GET_SINGLE_PICKUP_URL =
  WEBSITE_URL + "/get-single-pickup";
export const ADD_PRICE_OR_REMOVE_ITEMS_URL = WEBSITE_URL + "/agent-add-price-or-remove-item"
export const SEND_ORDER_FOR_CUSTOMER_APPROVAL_URL =WEBSITE_URL + '/send-order-for-customer-approval'

export const GET_ORDER_PICKUPS_URL =
  WEBSITE_URL + "/get-order-pickups";
export const SEND_DELIVERY_GUY_GPS_LOCATION_URL =
  WEBSITE_URL + "/set-delivery-guy-gps-location";
export const GET_DELIVERY_GUY_GPS_LOCATION_URL =
  WEBSITE_URL + "/get-delivery-guy-gps-location";
export const ACCEPT_TO_DELIVER_URL = WEBSITE_URL + "/accept-to-deliver";
export const PICKEDUP_ORDER_URL = WEBSITE_URL + "/pickedup-order";
export const REACHED_RESTAURANT_PICKUP_URL = WEBSITE_URL + "/reached-restaurant";
export const PICKEDUP_PICKUP_URL = WEBSITE_URL + "/pickedup-pickup";
export const DELIVER_ORDER_URL = WEBSITE_URL + "/deliver-order";
export const GET_REJECT_ORDER_URL = WEBSITE_URL + "/reject-to-deliver";

export const AGENT_DATA = WEBSITE_URL + "/agent-data";
export const REST_INTERVAL = WEBSITE_URL + "/reset-agent-interval";


export const GET_CURRENT_WORKING_HOURS =
  WEBSITE_URL + "/agent-current-working-hour";
export const GET_DUTY_IN = WEBSITE_URL + "/agent-duty-in";
export const GET_DUTY_OUT = WEBSITE_URL + "/agent-duty-out";
export const GET_CURRENT_AGENT_STATUS = WEBSITE_URL + "/current-agent-status";

//KYC PAGE APIS
export const GET_KYC_DATA_URL = WEBSITE_URL + "/get-agent-kyc-data";
export const UPLOAD_ADHAR_FRONT_URL = WEBSITE_URL + "/upload-adhar-front";
export const UPLOAD_ADHAR_BACK_URL = WEBSITE_URL + "/upload-adhar-back";
export const UPLOAD_PAN_FRONT_URL = WEBSITE_URL + "/upload-pan-front";
export const UPLOAD_PAN_BACK_URL = WEBSITE_URL + "/upload-pan-back";
export const UPLOAD_SMOKE_TEST_URL = WEBSITE_URL + "/upload-smoke-test";
export const UPLOAD_DRIVING_LISENCE_URL =
  WEBSITE_URL + "/upload-driving-lisence";
export const SAVE_BANKING_DETAILS_URL = WEBSITE_URL + "/upload-bank-details";

export const UPLOAD_DRIVING_LISENCE_BACK_URL =
  WEBSITE_URL + "/upload-driving-lisence-back";

export const UPLOAD_VEHICLE_RC_URL = WEBSITE_URL + "/upload-vehicle-rc";
export const UPLOAD_VEHICLE_INSURANCE_URL =
  WEBSITE_URL + "/upload-vehicle-insurance";
//KYC PAGE APIS

export const LEADER_BOARD_URL = WEBSITE_URL + "/get-leader-board-rankings";
export const UPDATE_KYC_URL = WEBSITE_URL + "/update-kyc";
export const COD_COLLECTION_VIEW_URL = WEBSITE_URL + "/cod-collection-history";
export const TASK_HISTORY_VIEW_URL = WEBSITE_URL + "/task-history";
export const TRANSACTION_HISTORY_VIEW_URL = WEBSITE_URL + "/get-agent-transactions";

export const GET_DELIVERY_GUY_PAYOUT = WEBSITE_URL + "/get-agent-payouts";
export const REQUEST_DELIVERY_GUY_PAYOUT =
  WEBSITE_URL + "/agent-request-payouts";
