import {
	LOGIN_DELIVERY_USER,
	LOGOUT_DELIVERY_USER,
	UPDATE_DELIVERY_USER_INFO,
	UPDATE_DELIVERY_ORDER_HISTORY,
	LEADER_BOARD,
	REQUEST_PAYOUT,
	VIEW_PAYOUT
} from "./actionTypes";
import { LOGIN_DELIVERY_USER_URL, UPDATE_DELIVERY_USER_INFO_URL, LEADER_BOARD_URL ,REQUEST_DELIVERY_GUY_PAYOUT ,GET_DELIVERY_GUY_PAYOUT} from "../../../configs";

import Axios from "axios";

export const loginDeliveryUser = (email, password) => (dispatch) => {
	Axios.post(LOGIN_DELIVERY_USER_URL, {
		email: email,
		password: password,
	})
		.then((response) => {
			const delivery_user = response.data;
			return dispatch({ type: LOGIN_DELIVERY_USER, payload: delivery_user });
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const logoutDeliveryUser = (delivery_user) => (dispatch) => {
	delivery_user = [];
	dispatch({
		type: LOGOUT_DELIVERY_USER,
		payload: delivery_user,
	});
};

export const updateDeliveryUserInfo = (user_id, token) => (dispatch) => {
	Axios.post(UPDATE_DELIVERY_USER_INFO_URL, {
		token: token,
		user_id: user_id,
	})
		.then((response) => {
			const data = { delivery_user: response.data, order_history: response.data.data.orders };
			return dispatch({ type: UPDATE_DELIVERY_USER_INFO, payload: data });
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateDeliveryOrderHistory = (order_history) => (dispatch) => {
	dispatch({
		type: UPDATE_DELIVERY_ORDER_HISTORY,
		payload: order_history,
	});
};

export const getTopAgents = (token) => dispatch => {
	Axios.post(LEADER_BOARD_URL, {
		token,
	})
		.then(response => {
			// console.log(response);

			const leader_board = response.data;
			return dispatch({ type: LEADER_BOARD, payload: { global_points: response.data.global_points, team_ranked_agents: response.data.team_ranked_agents, user_ranks: response.data.user_ranks, agent_team: response.data.agent_team } });
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const agentRequestPayout = (token) => dispatch => {
	console.log(token);
	Axios.post(REQUEST_DELIVERY_GUY_PAYOUT, {
		token,
	})
		.then(response => {
			// console.log(response);
			const data = response.data;
			return dispatch({ type: REQUEST_PAYOUT, payload: { success: response.data.success, data: response.data.data } });
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const getAgentPayouts = (token) => dispatch => {
	// console.log(token);
	Axios.post(GET_DELIVERY_GUY_PAYOUT, {
		token,
	})
		.then(response => {
			return dispatch({ type: VIEW_PAYOUT, payload: { success: response.data.success, payouts: response.data.payouts , amount: response.data.amount} });
		})
		.catch(function (error) {
			console.log(error);
		});
};
