import React, { Component } from "react";
// import { Redirect } from "react-router";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
// import { Link } from "react-router-dom";
import { Avatar, Switch, Dialog, Pane } from "evergreen-ui";
// import { render } from "@testing-library/react";
import { Offline, Online } from "react-detect-offline";
import {
  GET_DUTY_IN,
  GET_DUTY_OUT,
  GET_CURRENT_AGENT_STATUS,
  AGENT_DATA,
} from "../../../configs/index";
import axios from "axios";
import { BottomSheet } from "react-spring-bottom-sheet";

class Header extends Component {
  state = {
    on_duty: localStorage.getItem("on_duty"),
    is_shown: false,
  };

  static contextTypes = {
    router: () => null,
  };

  componentDidMount() {
    document.addEventListener("message", (message) => {
      const response = JSON.parse(message.data);
      // alert(response)
      if (response.set_job_out == false) {
        this.handleDutyOut(false);
      }
    });
    this.loadAgentData();

    if (this.state.on_duty) {
      if (window.ReactNativeWebView) {
        this.interval = setInterval(function () {
          const response = {
            type: "location_permission",
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }, 5000);
      }
    }

    axios
      .post(GET_CURRENT_AGENT_STATUS, {
        token: this.props.delivery_user.data.auth_token,
      })
      .then((response) => {
        this.setState({
          on_duty: response.data.on_duty,
          last_interval: response.data.last_interval_updated_at_in_mins,
          interval_flag: response.data.interval_flag,
        });

        if (response.data.on_duty) {
          if (this.props.setJobStatus) {
            this.props.setJobStatus(true);
          }
          if (window.ReactNativeWebView) {
            const response = {
              type: "start_tracking",
              user_id: this.props.delivery_user.data.id,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
          }
        } else {
          if (this.props.setJobStatus) {
            this.props.setJobStatus(false);
          }
          if (window.ReactNativeWebView) {
            const response = {
              type: "stop_tracking",
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
          }
        }
      });
  }
  loadAgentData = () => {

    console.log(this.props.delivery_user);
    axios.post(AGENT_DATA, {
      token: this.props.delivery_user.data?.auth_token,
    }).then((response) => {
      this.setState({
        agent_data: response.data.data,
        loading: false,
      });

      localStorage.setItem(
        "show_details_before_del",
        response.data.data.show_details_before_del
      );
    });
  }
  
  toogleDuty(checked) {
    this.loadAgentData();

    if (this.state.on_duty == false) {
      localStorage.setItem("on_duty", "true");
      axios
        .post(GET_DUTY_IN, {
          token: this.props.delivery_user.data.auth_token,
        })
        .then((response) => {
          this.setState({ on_duty: checked });
          if (this.props.setJobStatus) {
            this.props.setJobStatus(checked);
          }
          if (window.ReactNativeWebView) {
            const response = {
              type: "start_tracking",
              user_id: this.props.delivery_user.data.id,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
          }
        });
    } else {
      this.setState({ is_shown: true });
    }
  }

  handleDutyOut(checked) {
    localStorage.setItem("on_duty", "false");

    axios
      .post(GET_DUTY_OUT, {
        token: this.props.delivery_user.data.auth_token,
      })
      .then((response) => {
        this.setState({ on_duty: checked, is_shown: false });
        if (this.props.setJobStatus) {
          this.props.setJobStatus(checked);
        }
        if (window.ReactNativeWebView) {
          const response = {
            type: "stop_tracking",
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { delivery_user } = this.props;

    return this.props.viewOrderMode ? (
      <React.Fragment>
        <div className='header'>
          <button
            type='button'
            className='btn search-navs-btns back-button'
            style={{ position: "relative", color: "#003282" }}
            onClick={this.context.router.history.goBack}>
            <FeatherIcon icon='chevron-left' size='25' />
          </button>
          <div style={{ color: "#003282", fontWeight: "bold" }}>
            {this.props.order_id}
          </div>
        </div>
        <Offline polling={{ enabled: false }}>
          <div
            style={{
              zIndex: "999",
              position: "fixed",
              top: "5rem",
              width: "100%",
              background: "#ff5454",
              height: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: "700",
            }}>
            {localStorage.getItem("check_your_internet_connection")}
          </div>
        </Offline>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className='header' style={{backgroundColor: "#ffffff"}}>
        <Avatar name={delivery_user.data.name} size={35} />

          <img
            src={`https://fleet.howincloud.com/assets/tenant/${localStorage.getItem(
              "tenant"
            )}/logo-white.png`}
            alt='header-logo'
            style={{ width: "120px", height: "auto" }}
          />
          <div className='d-flex' style={{ alignItems: "center" }}>
            <Switch
              color={"green500"}
              checked={this.state.on_duty}
              onChange={(e) => this.toogleDuty(e.target.checked)}
              height={20}
              style={{ marginRight: "10px", marginTop: "5px" }}
            />
          </div>
        </div>
        <Offline polling={{ enabled: false }}>
          <div
            style={{
              zIndex: "999",
              position: "fixed",
              top: "5rem",
              width: "100%",
              background: "#ff5454",
              height: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: "700",
            }}>
            {localStorage.getItem("check_your_internet_connection")}
          </div>
        </Offline>
        {this.state.interval_flag ? (
          <div
            style={{
              position: "fixed",
              top: "3rem",
              width: "100%",
              background: "rgb(255, 84, 84)",
              height: "7rem",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              color: "white",
              fontWeight: "700",
              paddingBottom: "18px",
              fontSize: "16px",
            }}>
            Interval over ! Please Reset
          </div>
        ) : null}

        <BottomSheet
          open={this.state.is_shown}
          onDismiss={() => this.setState({ is_shown: false })}
          // header={<div className="sheetHeader text-dark">SHEET HEADER</div>}
          snapPoints={({ maxHeight }) => 0.50 * maxHeight}
          header={
            <div
              className='font-weight-bold text-black'
              style={{ fontSize: "initial" }}>
              {localStorage.getItem("going_off_duty")}
            </div>
          }

          // sibling={<div className="sheetFooter">YOUR MAIN FOOTER</div>}
        >
          <div className='p-4 text-black font-weight-bold text-center'>
            {localStorage.getItem("mark_as_duty_message")}
          </div>

          <div
            onClick={() => this.handleDutyOut(false)}
            className=' mt-20 '
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            <div className='col-12'>
              <div
                className='card '
                style={{
                  borderRadius: "15px",
                  border: "none",
                  outline: "none",
                  boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                  WebkitBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                  MozBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                  backgroundColor: "#ff5858",
                }}>
                <div className='p-10 text-center'>
                  <span
                    className='text-white pt-3'
                    style={{ fontSize: "1.1em", fontWeight: "600" }}>
                    {localStorage.getItem("confirm")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </BottomSheet>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_user: state.delivery_user.delivery_user,
});

export default connect(mapStateToProps)(Header);
