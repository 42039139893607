import React, { Component } from "react";
import ApexCharts from "react-apexcharts";

class OrderChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{ name: "Orders", data: [0,0,0,0,0,0,0] }],
      options: {
        height: 350,
        type: "area",
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
    };
  }
  componentDidMount() {
    const series = [{ name: "Orders", data: this.props.chart_data.chartData }];
    const options = {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false
        },
      },
      xaxis: {
        categories: this.props.chart_data.days
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
    };
    
    this.setState({ series,options });
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.chart_data != this.props.chart_data){
      const series = [{ name: "Orders", data:nextProps.chart_data.chartData }];
      const options = {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false
          },
        },
        xaxis: {
          categories: nextProps.chart_data.days
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
      };
      
      this.setState({ series,options });
    }
   
  }



  render() {
    return (
      <div id="chart" style={{ margin: "10px", right: "8px" }}>
        <ApexCharts
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </div>
    );
  }
}

export default OrderChart;
