import React, { Component } from 'react';
import BackButtonHeader from '../../Elements/BackButtonHeader';
import Loader from '../../Delivery/Loader';
// import DatePicker from "react-datepicker";
import Axios from "axios";
import { connect } from "react-redux";
import { Input, Button, DatePicker,Divider  } from 'antd';

import moment from 'moment';
import Moment from 'moment';

import { updateDeliveryUserInfo } from "../../../services/Delivery/user/actions";

import { COD_COLLECTION_VIEW_URL } from '../../../configs';


import "react-datepicker/dist/react-datepicker.css";


class CodCollectionHistory extends Component {


    state = {
        collection_history: [],
    }

    onRequest = () => {

        this.setState({ loading: true });
        Axios.post(COD_COLLECTION_VIEW_URL, {
            token: this.props.delivery_user.data.auth_token,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            data_mode: this.state.data_mode
        })
            .then(response => {
                console.log(response);

                const data = response.data;
                if (data) {
                    console.log(data);

                    this.setState({ collection_history: data, loading: false });
                }
            });
    }


    state = {
        start_date: new Date(),
        end_date: new Date(),
        loading: false,
        data_mode: 'unsettled'
    };

    handleStartDate = (event) => {
      
        this.setState({
            start_date: new Date(event),
        });
    };

    handleEndDate = (event) => {
        this.setState({
            end_date: new Date(event),
        });
    };

    render() {
        return (
            <React.Fragment>
                <BackButtonHeader title={localStorage.getItem('cash_on_hand_collections')} />


                 {/* {this.state.loading &&
                 <Loader />

                }  */}

                <div className="container">

                <label style={{  color: "black", fontSize: "13px",fontWeight: "bold", fontFamily: "Poppins", }}>Data Mode</label>
                <div className="d-flex justify-content-around">
                <Button onClick={() => this.setState({ data_mode : 'unsettled', collection_history: []})}  type={this.state.data_mode == 'unsettled' ? 'primary' : 'ghost' } size={'large'}>Un-Setteled Log</Button>
                <Button onClick={() => this.setState({ data_mode : 'settled', collection_history: []})}  type={this.state.data_mode == 'settled' ? 'primary' : 'ghost' } size={'large'}>Settled Log</Button>
                </div>    
                <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                        }}
                    >
                        <div className="d-flex justify-content-around  text-black">
                            <div style={{ width: '45%' }}><label

                                style={{
                                    color: "black",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                }}
                            >
                                {localStorage.getItem('from')}
                            </label>


                                <DatePicker 
                                onChange={this.handleStartDate}
                                    size={'large'}
                                    defaultValue={moment(new Date(), 'DD-MM-YYYY')}
                                    format={'DD-MM-YYYY'}
                                />


                            </div>
                            <div style={{ width: '45%' }}><label

                                style={{
                                    color: "black",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    fontFamily: "Poppins",
                                }}
                            >
                            {localStorage.getItem('to')}

                            </label>

                                <DatePicker
                                    onChange={this.handleEndDate}
                                    size={'large'}
                                    defaultValue={moment(new Date(), 'DD-MM-YYYY')}
                                    format={'DD-MM-YYYY'}
                                />

                            </div>


                        </div>
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <Button onClick={this.onRequest} loading={this.state.loading} type="primary" size={'large'}>{localStorage.getItem('view_collections')}</Button>
                        </div>
                    </div>

                    <Divider orientation="left" plain>
                        <b>{localStorage.getItem('collections')} ({!this.state.collection_history ? 0 : this.state.collection_history.length})</b>
                    </Divider>
                    {this.state.collection_history && this.state.collection_history.length > 0
                        ? this.state.collection_history.map((collection_histor, index) =>


                            <div className="row mt-4">
                                <div className="col-12 ">
                                    <div class="card " style={{ borderRadius: '10px', }} >
                                        <div className="  p-20" style={{
                                            border: 'none',
                                            borderRadius: '10px',
                                            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                                            WebkitBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)",
                                            MozBoxShadow: "0px 1px 5px 0px rgba(0,0,0,0.29)"
                                        }}>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="">
                                                        <span className="text-dark font-weight-bold">
                                                            {Moment(collection_histor.created_at).format('YYYY-MM-DD')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="text-right">
                                                        <span className="text-dark font-weight-bold" style={localStorage.getItem('lang') && localStorage.getItem('lang')=='en'?{direction:'ltr'}:{direction:'rtl'}}>
                                                            {localStorage.getItem('currency')} {collection_histor.amount}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    {collection_histor.description ? (
                                                        <span className="text-justify text-muted">
                                                            {collection_histor.description}
                                                        </span>
                                                    ) : (
                                                        <span className="text-justify text-muted">
                                                            {collection_histor.message}
                                                        </span>
                                                    )}

                                                </div>
                                            </div>
                                            {collection_histor.orders.map((order, index) => (
                                                <div className="d-flex justify-content-between text-black">
                                                    <div>{order.client_order_identifier}</div>
                                                    <div>{order.order_cod}</div>
                                                </div>
                                            ))}
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    delivery_user: state.delivery_user.delivery_user,
});

export default connect(
    mapStateToProps,
    { updateDeliveryUserInfo }
)(CodCollectionHistory);