import React, { Component } from "react";
import { Spinner, Pane } from "evergreen-ui";

class Loading extends Component {
  render() {
    return (
      <div style={{"position":"absolute","top":"0px","zIndex":"9999999","width":"100%","background":"#ffffffe6"}}>
        <Pane display="flex" alignItems="center" justifyContent="center" height={900}>
                          <Spinner />
        </Pane>
      </div>
    );
  }
}

export default Loading;
