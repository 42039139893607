import axios from "axios";
import {
  UPLOAD_ADHAR_BACK_URL,
  UPLOAD_ADHAR_FRONT_URL,
  UPLOAD_DRIVING_LISENCE_URL,
  UPLOAD_PAN_FRONT_URL,
  UPLOAD_SMOKE_TEST_URL,
  UPLOAD_DRIVING_LISENCE_BACK_URL,
  UPLOAD_VEHICLE_RC_URL,
  UPLOAD_VEHICLE_INSURANCE_URL,
} from "../../../configs";

export function saveAadharFront(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_ADHAR_FRONT_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}

export function saveAadharBack(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_ADHAR_BACK_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}

export function savePan(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_PAN_FRONT_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}

export function saveLicense(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_DRIVING_LISENCE_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}
export function saveLicenseBack(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_DRIVING_LISENCE_BACK_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}
export function saveSmokeTest(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_SMOKE_TEST_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}

export function saveVehicleRc(formData, handleUploadProgress = () => {}) {
  axios.post(UPLOAD_VEHICLE_RC_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}

export function saveVehicleInsurance(
  formData,
  handleUploadProgress = () => {}
) {
  axios.post(UPLOAD_VEHICLE_INSURANCE_URL, formData, {
    onUploadProgress: handleUploadProgress,
  });
}
