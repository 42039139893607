import React, { Component } from "react";
import Axios from "axios";
import { connect } from "react-redux";

class CheckVersion extends Component {
  state = {
    updating: false,
  };

  componentDidMount() {
    Axios.get("/version.json").then((response) => {
      //getting new data from version.json file
      const forceNewSettingsServerVersion =
        response.data.forceNewSettingsVersion;
      const forceCacheClearServerVersion = response.data.forceCacheClearVersion;
      // const forceLogoutAllCustomers = response.data.forceLogoutAllCustomers;
      //logging all data
      // console.log("Client Settings Version: ", localStorage.getItem("forceNewSettingsVersion"));
      // console.log("Server Settings Version: ", forceNewSettingsServerVersion);
      // console.log("Client Cache Version: ", localStorage.getItem("forceCacheClearVersion"));
      // console.log("Server Cache Version: ", forceCacheClearServerVersion);

      //firstime user donot have settings so set setting... and donot load updating section
      if (
        localStorage.getItem("forceNewSettingsVersion") === null &&
        localStorage.getItem("forceCacheClearVersion") === null
      ) {
        localStorage.setItem(
          "forceNewSettingsVersion",
          forceNewSettingsServerVersion
        );
        localStorage.setItem(
          "forceCacheClearVersion",
          forceCacheClearServerVersion
        );
      } else {
        //settings are already set so old user so call update method if settings changed
        if (
          localStorage.getItem("forceNewSettingsVersion") !==
          forceNewSettingsServerVersion
        ) {
          console.warn("Getting New Settings");
          localStorage.setItem(
            "forceNewSettingsVersion",
            forceNewSettingsServerVersion
          );
        }

        if (
          localStorage.getItem("forceCacheClearVersion") !==
          forceCacheClearServerVersion
        ) {
          const clearLocalStorage = () => {
            return new Promise((resolve) => {
              console.warn("Clear Local Storage");
              // take some backup
              let geoLocation = localStorage.getItem("geoLocation");
              let userSetAddress = localStorage.getItem("userSetAddress");
              let storeColor = localStorage.getItem("storeColor");
              let storeLogo = localStorage.getItem("storeLogo");
              let updatingMessage = localStorage.getItem("updatingMessage");
              let userPreferedLanguage = localStorage.getItem(
                "userPreferedLanguage"
              );

              //clear
              localStorage.clear();

              resolve("Completed clearLocalStorage");
            });
          };

          const clearCacheStorage = () => {
            return new Promise((resolve) => {
              console.warn("Clear Cache Storage");
              navigator.serviceWorker
                .getRegistrations()
                .then(function (registrations) {
                  for (let registration of registrations) {
                    registration.unregister();
                  }
                });
              if (caches) {
                // console.log("CACHES:", caches);
                caches.keys().then(function (names) {
                  for (let name of names) caches.delete(name);
                });
              }
              resolve("Completed clearCacheStorage");
            });
          };

          const updateClientVersion = () => {
            return new Promise((resolve) => {
              console.warn("Update Client Version");
              localStorage.setItem(
                "forceNewSettingsVersion",
                forceNewSettingsServerVersion
              );
              localStorage.setItem(
                "forceCacheClearVersion",
                forceCacheClearServerVersion
              );
              resolve("Completed updateClientVersion");
            });
          };

          const reloadBrowser = () => {
            return new Promise((resolve) => {
              setTimeout(() => {
                this.setState({ updating: false }, () => {
                  window.location.reload(true);
                  resolve("Completed reloadBrowser");
                });
              }, 3 * 1000);
            });
          };

          async function doProcess() {
            await clearLocalStorage();
            await clearCacheStorage();
            await updateClientVersion();
            await reloadBrowser();
          }
          this.setState({ updating: true });
          // Promise.all([clearLocalStorage(), clearCacheStorage(), updateClientVersion(), reloadBrowser()]);

          doProcess();
        }
        // if (localStorage.getItem("forceLogoutAllCustomers") !== null) {
        // 	if (localStorage.getItem("forceLogoutAllCustomers") !== forceLogoutAllCustomers) {
        // 		console.log("Logged out");
        // 		this.props.logoutUser();
        // 		localStorage.setItem("forceLogoutAllCustomers", forceLogoutAllCustomers);
        // 	}
        // } else {
        // 	localStorage.setItem("forceLogoutAllCustomers", forceLogoutAllCustomers);
        // }
      }
    });
  }
  render() {
    const { updating } = this.state;
    return (
      <React.Fragment>
        {updating && (
          <React.Fragment>
            <div className='update-full-notification'>
              <span className='spin-load' />
            </div>
            <div
              className='update-full-notification'
              style={{ zIndex: 9999999999 }}>
              <h1 className='d-flex' style={{ marginTop: "8rem" }}>
                {localStorage.getItem("updatingMessage")}
              </h1>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

// export default CheckVersion;
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CheckVersion);
