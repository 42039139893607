import React, { Component } from "react";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import axios from "axios";
import { Spinner, Pane } from "evergreen-ui";
import { GET_CURRENT_WORKING_HOURS } from "../../../../configs/index";
class Timer extends Component {
  componentDidMount() {

    axios
      .post(GET_CURRENT_WORKING_HOURS, {
        token: this.props.delivery_user.auth_token,
      })
      .then((response) => {

        this.setState({
          workingHourData: response.data,
          loading: false
        });
      });
  }

  state = {
    loading: true,
    workingHourData: [],
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ position: "relative", marginTop: '20px' }}>

          <div style={{ fontSize: '50px', textAlign: 'center', fontWeight: '600' }}>
            {this.state.workingHourData.today_working_minutes} <span style={{ fontSize: '12px' }}>{localStorage.getItem('mins')}</span>
          </div>
          <div style={{"textAlign":"center","fontWeight":"600","fontSize":"11px","marginBottom":"50px","color":"#2C6ADE"}}> <span style={{"borderRadius":"20px","background":"#EEF3FE","padding":"6px 16px"}}>{localStorage.getItem('on_duty_today')}</span> </div>

          {this.state.loading === true && (
            <div style={{ position: "absolute", top: "31%", right: "47%" }}>
              <Spinner size={25} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  delivery_user: state.delivery_user.delivery_user.data,
});

export default connect(mapStateToProps)(Timer);
