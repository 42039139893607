/* eslint-disable import/no-anonymous-default-export */
import { LOGIN_DELIVERY_USER, LOGOUT_DELIVERY_USER, UPDATE_DELIVERY_USER_INFO, UPDATE_DELIVERY_ORDER_HISTORY, LEADER_BOARD ,REQUEST_PAYOUT, VIEW_PAYOUT} from "./actionTypes";

const initialState = {
    delivery_user: [],
    order_history: [],
    global_points: [],
    team_ranked_agents: [],
    user_ranks: [],
    agent_team: [],
    payouts: [],
    amount:[]
    
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_DELIVERY_USER:
            return { ...state, delivery_user: action.payload };
        case LOGOUT_DELIVERY_USER:
            return { ...state, delivery_user: action.payload };
        case UPDATE_DELIVERY_USER_INFO:
            return { ...state, delivery_user: action.payload.delivery_user, order_history: action.payload.order_history };
        case UPDATE_DELIVERY_ORDER_HISTORY:
            return { ...state, order_history: action.payload };
        case LEADER_BOARD:
            return { ...state, global_points: action.payload.global_points, team_ranked_agents: action.payload.team_ranked_agents, user_ranks: action.payload.user_ranks, agent_team: action.payload.agent_team };
        case REQUEST_PAYOUT:
            return { ...state, success: action.success, data: action.payload.data };
        case VIEW_PAYOUT:
            return { ...state, success: action.success, payouts: action.payload.payouts , amount: action.payload.amount };
        default:
            return state;
    }
}
